import React from 'react';
import Joyride, { CallBackProps, Step } from "react-joyride";
import { useAppContext } from "../contexts";
import { useLocation, useNavigate } from "react-router-dom";

const steps: Step[] = [
    {
        target: "#teamProjectGrid",
        content: (<>
            <p>You can create projects for your team here.
                <br />
                Everyone on your team will share these projects.</p>
        </>),
        data: {
            next: "/app/projects"
        },
        placement: "top-start",
        placementBeacon: "top",
        title: "Your team's projects",
    },
    {
        target: "#personalProjectGrid",
        content: (<>
            <p>Or track your work privately in personal projects.
                <br />
                We've created a project with some tasks to show you around.</p>
        </>),
        data: {
            prev: "/app/projects",
            next: "/app/projects/WELCOME",
        },
        spotlightClicks: true,
        placement: "top-start",
        placementBeacon: "top",
        title: "Personal projects"
    },
    {
        target: "#openProjectButton",
        content: (<>
            <p>You can view the tasks in a project by clicking the open project button</p>
        </>),
        data: {
            prev: "/app/projects",
            next: "/app/projects/WELCOME"
        }
    },
    {
        target: "#activeProjectSidebarIcon",
        content: (<>
            <p>The currently open project will be shown here.</p>
        </>),
        data: {
            prev: "/app/projects/WELCOME",
            next: "/app/tasks/WELCOME"
        },
        offset: -10,
        placement: "right"
    },
    {
        target: "#utilityCard",
        content: (<>
            <p>The utility card has other uses besides creating items.
                <br /><br />
                Just drag and drop a task on to the utility card to close tasks or archive closed tasks.</p>
        </>),
        data: {
            prev: "/app/projects/WELCOME",
            next: "/app/tasks/WELCOME"
        },
        offset: -10,
        placement: "auto",
        placementBeacon: "top",
        title: "Utility card"
    },
    {
        target: "#inProgressSwimlane",
        content: (<>
            <p>Drag tasks into the swimlanes to step them through the workflow</p>
        </>),
        data: {
            prev: "/app/tasks",
            next: "/app/tasks/id/WELCOME-1"
        },
        title: "Swimlanes",
        placement: "right",
    },
    {
        target: "#taskDetailSidebar",
        content: (<>
            <p>The task sidebar contains all the most relevant metadata and controls for a task</p>
        </>),
        data: {
            prev: "/app/tasks",
            next: "/app/tasks/id/WELCOME-1"
        },
        placement: "left"
    },
    {
        target: "#taskControlPanel",
        content: (<>
            <p>Additional controls can be found in the control panel above</p>
        </>),
        data: {
            prev: "/app/tasks/id/WELCOME-1",
            next: "/app/tasks/id/WELCOME-1"
        }
    },
    {
        target: "#taskComments",
        content: (<>
            <p>Comments are found below the task description</p>
        </>),
        data: {
            prev: "/app/tasks/id/WELCOME-1",
            next: "/app/tasks/id/WELCOME-1"
        }
    },
    {
        target: "#omniSearch",
        content: (<>
            <p>You can quickly get to tasks you're looking for with the search bar</p>
        </>),
        data: {
            prev: "/app/tasks/id/WELCOME-1",
            next: "/app/tasks/id/WELCOME-1"
        }
    },
    {
        target: "#instructSidebarIcon",
        content: (<>
            <p>If you have any questions just ask our AI for help!
                <br /><br />
                Close out all the tasks in the welcome project to complete the tour.</p>
        </>),
        data: {
            prev: "/app/tasks/id/WELCOME-1",
        }
    }
];

function Tour() {
    const { theme, tourState, setTourState } = useAppContext();
    const location = useLocation();
    const navigate = useNavigate();

    const handleJoyride = React.useCallback((data: CallBackProps) => {
        const {
            action,
            lifecycle,
            step: { data: { next, prev } },
            type
        } = data;
        const isPrevious = action === "prev" && type === "step:after";

        if (lifecycle === "init") {
            return
        }

        if (isPrevious) {
            if (tourState.stepIndex > 0) {
                setTourState({ ...tourState, stepIndex: tourState.stepIndex - 1 });
            }
            if (prev && prev !== location.pathname) {
                setToggle(false);
                navigate(prev);
            }
        } else if (action === "next" && type === "step:after") {
            let onboarding = tourState.onboarding;
            const stepIndex = tourState.stepIndex + 1;
            if (stepIndex >= tourState.steps.length) {
                onboarding = false;
            }
            setTourState({ ...tourState, stepIndex, onboarding });
            if (next && next !== location.pathname) {
                setToggle(false);
                navigate(next);
            }
        }
    }, [location.pathname, tourState, setTourState, navigate]);

    // This is a hack to get the tour to restart when the page changes
    const [toggle, setToggle] = React.useState(false);

    React.useEffect(() => {
        if (tourState.steps !== steps) {
            setTourState({ ...tourState, steps });
        }
    }, [tourState, setTourState]);

    React.useEffect(() => {
        setTimeout(() => {
            setToggle(tourState.onboarding);
        }, 500);
    }, [tourState.onboarding, toggle]);

    let styles = {};
    if (theme === "light") {
        styles = {
            options: {
                beaconSize: 96,
                arrowColor: "#fff",
                backgroundColor: "#fff",
                primaryColor: "#4281A4",
                textColor: "#000"
            }
        };
    } else {
        styles = {
            options: {
                beaconSize: 96,
                arrowColor: "#101010",
                backgroundColor: "#101010",
                primaryColor: "#7AE7C7",
                textColor: "#fff"
            }
        };
    }

    try {
        return (<>
            {tourState.onboarding && <Joyride
                callback={handleJoyride}
                continuous
                run={toggle}
                stepIndex={tourState.stepIndex}
                steps={tourState.steps}
                styles={styles}
            />}
        </>);
    } catch {
        // This library is a buggy mess
        return <></>;
    }
}
export default Tour;
