import { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { ProviderProps } from "./ContextTypes";
import { cache, isDevelopment, eventEmitter, apiFetch, apiFetchNoContent } from ".";

import defaultImage from "../assets/unknown.jpg";
import beEng from "../assets/be-eng.webp";
import dopsEng from "../assets/dops-eng.webp";
import feManager from "../assets/fe-manager.webp";
import beManager from "../assets/be-manager.webp";
import dopsManager from "../assets/dops-manager.webp";

export interface OrganizationData {
    id: string;
    name: string;
    created: string;
    pricingPlanId: string;
    metadata: OrganizationMetadata;
}

export interface OrganizationMetadata {
    max_capacity?: number;
    max_projects?: number;
    credits?: number;
    premium_features?: unknown;
    last_payment_date?: string;
    overdue_since?: string;
    description?: string;
    notes?: string;
    region?: string;
    timezone?: string;
    contact?: string;
    phone?: string;
}

export type OrganizationRole = "owner" | "admin" | "member" | { "memberWithPermissions": string[] };

export interface UserEmailSettings {
    notifications: boolean;
    frequency: string;
    channels: string[];
}

export interface UserPersonalSettings {
    timezone?: string;
    currency?: string;
    units?: string;
    phone?: string;
    billingAddress?: unknown;
    mailingAddress?: unknown;
    locale?: string;
    theme?: string;
    applicableLaws?: string[];
    consents?: string[];
    preferences?: unknown;
}

export interface UserHistoryData {
    locationData?: unknown;
    favorites?: unknown;
    recent?: unknown;
    history?: unknown;
}

export interface UserNotificationSettings {
    notifications: boolean;
    channels: string[];
}

export interface UserSecuritySettings {
    knownDevices?: unknown;
    knownIps?: unknown;
    mfa?: unknown;
}

export interface UserSettings {
    lastLogin?: string;
    email: UserEmailSettings;
    personal: UserPersonalSettings;
    history: UserHistoryData;
    notifications: UserNotificationSettings;
    scheduling?: unknown;
    security: UserSecuritySettings;
}

export interface UserData {
    id: string;
    created: string;
    username: string;
    email: string;
    jobId?: string;
    role?: string;
    orgRole?: OrganizationRole;
    org?: OrganizationData;

    // Metadata
    jobTitle: string;
    givenName?: string;
    familyName?: string;
    settings?: UserSettings;

    imageId?: string;
}

export function isOrgAdmin(user: UserData | null): boolean {
    return user?.orgRole === "owner" || user?.orgRole === "admin";
}

export function isOrgPlanner(user: UserData | null): boolean {
    if (!user?.orgRole) {
        return false;
    }
    if (typeof (user.orgRole) === "object") {
        return user.orgRole.memberWithPermissions.includes("planner");
    } else {
        return user.orgRole === "owner" || user.orgRole === "admin";
    }
}

export function orgRoleString(orgRole: OrganizationRole | undefined): string {
    if (typeof orgRole === "object") {
        return "Member";
    }

    switch (orgRole) {
        case "owner":
            return "Owner";
        case "admin":
            return "Admin";
        case "member":
            return "Member";
        default:
            return "Unknown";
    }
}

function testUsers(): UserData[] {
    cache.setPortrait('1', defaultImage);
    cache.setPortrait('2', beEng);
    cache.setPortrait('3', dopsEng);
    cache.setPortrait('4', feManager);
    cache.setPortrait('5', beManager);
    cache.setPortrait('6', dopsManager);
    cache.setPortrait('7', defaultImage);

    const users = [
        {
            id: "1",
            created: "2024-01-01T00:00:00",
            username: "FILIP",
            email: "filip@subseq.io",
            jobTitle: "Frontend Engineer",
            jobId: "2",
        },
        {
            id: "2",
            created: "2024-01-01T00:00:00",
            username: "COCO",
            email: "coco@subseq.io",
            jobTitle: "Backend Engineer",
        },
        {
            id: "3",
            created: "2024-01-01T00:00:00",
            username: "RONAN",
            email: "ronan@subseq.io",
            jobTitle: "Devops Engineer",
        },
        {
            id: "4",
            created: "2024-01-01T00:00:00",
            username: "GARRETT",
            email: "garrett@subseq.io",
            jobTitle: "Frontend Manager",
        },
        {
            id: "5",
            created: "2024-01-01T00:00:00",
            username: "GABE",
            email: "gabe@subseq.io",
            jobTitle: "Backend Manager",
        },
        {
            id: "6",
            created: "2024-01-01T00:00:00",
            username: "NIKKI",
            email: "nikki@subseq.io",
            jobTitle: "Devops Manager",
        },
        {
            id: "7",
            created: "2024-01-01T00:00:00",
            username: "CARMACK",
            email: "carmack@subseq.io",
            jobTitle: "Software Architect",
        },
    ];
    return users;
}

export async function getUsers(page: number): Promise<UserData[]> {
    if (isDevelopment) {
        if (page == 1) {
            return testUsers();
        } else {
            return [];
        }
    }

    return await apiFetch(`/user/list/${page}`, {
        method: "GET",
    });
}

export async function verifyEmail(token: string): Promise<{ message: string }> {
    if (isDevelopment) {
        return { message: "verified" };
    }
    return await apiFetch(`/email/verify?id=${token}`, { method: 'POST', });
}

export async function resendEmail() {
    return await apiFetch('/email/verify', {
        method: 'PUT',
    });
}

export interface Invite {
    id: string;
    created: string;
    orgName: string;
    invitedBy: UserData;
}

export async function inviteList(): Promise<Invite[]> {
    return await apiFetch("/org/user/invite/list", {
        method: "GET",
    });
}

export async function inviteResponse(inviteId: string, accept: boolean) {
    try {
        await apiFetch(`/org/user/invite/${inviteId}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ inviteResponse: accept }),
        });
        return true;
    } catch (error) {
        return false;
    }
}

async function inviteUserToOrg(userEmail: string): Promise<boolean> {
    try {
        await apiFetch('/org/user/invite', {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: userEmail }),
        });
        return true;
    } catch (error) {
        const text = (await (error as Response).json() as { error: string }).error;
        eventEmitter.emit("ERROR", `${text}`);
        return false;
    }
}

async function removeUserFromOrg(userId: string): Promise<boolean> {
    try {
        await apiFetchNoContent(`/org/user/${userId}`, {
            method: "DELETE",
        });
        return true;
    } catch (error) {
        return false;
    }
}

export interface UsersContextProps {
    users: UserData[];
    indexesById: Record<string, number>;
    addUser: (user: UserData) => void;
    clearUserCache: () => void;
    updateUser: (user: UserData) => void;
    inviteToOrg: (email: string) => void;
    removeFromOrg: (userId: string) => void;
}
export const UsersContext = createContext<UsersContextProps | undefined>(undefined);

const UsersProvider: React.FC<ProviderProps> = ({ children }) => {
    const [users, setUsers] = useState<UserData[]>([]);
    const [userCache, setUserCache] = useState(Date.now());

    const indexesById = useMemo(() => {
        const indexesById: Record<string, number> = {};
        for (let i = 0; i < users.length; i += 1) {
            indexesById[users[i].id] = i;
        }
        return indexesById;
    }, [users]);

    const clearUserCache = useCallback(() => {
        setUserCache(Date.now());
    }, []);

    useEffect(() => {
        const fetchUsers = async () => {
            let page = 1;
            let lastLen = -1;
            let newUsers: UserData[] = [];
            while (newUsers.length !== lastLen) {
                lastLen = newUsers.length;
                newUsers = [...newUsers, ...await getUsers(page)];
                setUsers(newUsers);
                page += 1;
            }
        };
        fetchUsers();
    }, [userCache]);

    const updateUser = useCallback((task: UserData) => {
        const newUsers = users.map(item => {
            if (item.id === task.id) {
                return task;
            }
            return item;
        });
        setUsers(newUsers);
    }, [users]);

    const addUser = useCallback((user: UserData) => {
        if (!(user.id in indexesById)) {
            const newUsers = [...users, user];
            setUsers(newUsers);
        }
    }, [setUsers, users, indexesById]);

    useEffect(() => {
        const addUserEvent = ({ user }: { user: UserData }) => {
            addUser(user);
        };

        eventEmitter.on("USER-ADD", addUserEvent);
        return () => {
            eventEmitter.off("USER-ADD", addUserEvent);
        };
    }, [addUser]);

    const inviteToOrg = useCallback((email: string) => {
        inviteUserToOrg(email).then(() => { });
    }, []);

    const removeFromOrg = useCallback((userId: string) => {
        removeUserFromOrg(userId).then((success) => {
            if (success) {
                const newUsers = users.filter(user => user.id !== userId);
                setUsers(newUsers);
            } else {
                eventEmitter.emit("ERROR", "You don't have permission to remove this user from the organization.");
            }
        });
    }, [users]);

    const value = {
        users,
        addUser,
        clearUserCache,
        indexesById,
        updateUser,
        inviteToOrg,
        removeFromOrg
    };
    return (<UsersContext.Provider value={value}>
        {children}
    </UsersContext.Provider>);
};
export default UsersProvider;
