"use client"

import * as React from "react"
import { addDays, format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { DateRange } from "react-day-picker"

import { cn } from "@/lib/utils"
import { Button } from "./button"
import { Calendar } from "./calendar"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "./popover"
import "react-day-picker/style.css";

function getLastDayOfWeek(date: Date = new Date()): Date {
    const dayOfWeek = date.getDay(); // 0 (Sunday) - 6 (Saturday)
    const diff = 6 - dayOfWeek;
    const lastDayOfWeek = new Date(date);
    lastDayOfWeek.setDate(date.getDate() + diff);
    return lastDayOfWeek;
}

function getLastDayOfMonth(date: Date = new Date()): Date {
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return lastDayOfMonth;
}

function getLastDayOfYear(date: Date = new Date()): Date {
    const lastDayOfYear = new Date(date.getFullYear(), 11, 31);
    return lastDayOfYear;
}

function getLastDayOfQuarter(date: Date = new Date()): Date {
    const month = date.getMonth();
    const year = date.getFullYear();

    let lastMonthOfQuarter: number;
    if (month >= 0 && month <= 2) {
        lastMonthOfQuarter = 2;  // Q1: January (0) - March (2)
    } else if (month >= 3 && month <= 5) {
        lastMonthOfQuarter = 5;  // Q2: April (3) - June (5)
    } else if (month >= 6 && month <= 8) {
        lastMonthOfQuarter = 8;  // Q3: July (6) - September (8)
    } else {
        lastMonthOfQuarter = 11; // Q4: October (9) - December (11)
    }

    const lastDayOfQuarter = new Date(year, lastMonthOfQuarter + 1, 0);
    return lastDayOfQuarter;
}

export type Timeline = "week" | "sprint" | "month" | "quarter" | "year";

interface DatePickerWithRangeProps {
    className?: string;
    innerClasses?: string;
    timeline: Timeline | null;
    onRangeChange: (range: DateRange) => void;
}

export function DatePickerWithRange({
    className,
    innerClasses,
    timeline,
    onRangeChange,
}: DatePickerWithRangeProps) {
    const [today] = React.useState(new Date(Date.now()));
    let toDate = today;

    if (timeline === "week") {
        toDate = getLastDayOfWeek(today);
    } else if (timeline === "sprint") {
        toDate = addDays(getLastDayOfWeek(today), 7);
    } else if (timeline === "month") {
        toDate = getLastDayOfMonth(today);
    } else if (timeline === "quarter") {
        toDate = getLastDayOfQuarter(today);
    } else if (timeline === "year") {
        toDate = getLastDayOfYear(today);
    }
    const [to, setTo] = React.useState<Date>(toDate);
    React.useEffect(() => {
        setTo(toDate);
    }, [timeline]);

    const [date, setDate_] = React.useState<DateRange>({ from: today, to })

    const setDate = (date: DateRange) => {
        setDate_(date)
        onRangeChange(date)
    };

    React.useEffect(() => {
        setDate({ from: today, to });
    }, [today, to])

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    id="date"
                    variant={"outline"}
                    className={cn(
                        className,
                        "w-[300px] justify-start text-left font-normal",
                        !date && "text-muted-foreground"
                    )}
                >
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {date?.from ? (
                        date.to ? (
                            <>
                                {format(date.from, "LLL dd, y")} -{" "}
                                {format(date.to, "LLL dd, y")}
                            </>
                        ) : (
                            format(date.from, "LLL dd, y")
                        )
                    ) : (
                        <span>Pick a date</span>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent
                className={cn("w-auto p-0", innerClasses)}
                align="start">
                <Calendar
                    required={true}
                    mode="range"
                    defaultMonth={date?.from}
                    selected={date}
                    onSelect={setDate}
                    numberOfMonths={2}
                />
            </PopoverContent>
        </Popover>
    )
}
