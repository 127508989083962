import init, { destroy, draw, healthy, Chart } from "@subseq/planner-wasm";
import { useRef, useEffect, useState, useCallback } from "react";
import { style } from "typestyle";
import { FaArrowDown, FaArrowLeft, FaArrowRight, FaArrowUp, FaRecycle } from "react-icons/fa6";
import { Link, useNavigate, useParams } from "react-router-dom";

import Spinner from "@/components/Spinner";
import unknownUserImage from "@/assets/unknown.jpg";
import { Button } from "@/components/ui/button";
import { ChartTask, ChartPlan, Constraint, EventSegment } from "@/contexts/Chart";
import { Label } from "@/components/ui/label";
import { apiFetch, cache, useAppContext, useChartContext, useMilestoneContext } from "@/contexts";
import { isOrgPlanner } from "@/contexts/User";

function getRgbColor(colorName: string): string | undefined {
    if (colorName.startsWith("#")) {
        return colorName;
    }

    // Create a temporary DOM element
    const tempElement = document.createElement("div");
    tempElement.style.color = colorName;
    document.body.appendChild(tempElement);

    // Get the computed color style
    const computedColor = window.getComputedStyle(tempElement).color;

    // Remove the temporary element from the DOM
    document.body.removeChild(tempElement);

    const rgb = computedColor;
    const match = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
    if (match) {
        const intStrings = [match[1], match[2], match[3]];
        const hexStrings = intStrings.map((x) => {
            const s = parseInt(x).toString(16);
            return (x.length === 1) ? "0" + s : s;
        });
        return "#" + hexStrings.join("");
    }
    return undefined;
}

const ganttChartStyle = style({
    width: "100%",
    height: "calc(100% - 8rem)",
    display: "flex",
    padding: "10px",
    justifyContent: "center",
    alignItems: "center",
    $nest: {
        canvas: {
            width: "100%",
            height: "100%",
        },
    },
});

interface Segment {
    start: number;
    duration: number;
}

interface Task {
    id: string;
    row: number;
    slug: string;
    titles: string[];
    priority: number;
    attention: number;
    requirements: number[];
    segments: Segment[];
    open: boolean;
    assigned: string;

    actualStart?: number;
    actualDuration?: number;
    dueDate?: number;
    dependencies?: ChartLink[];
    parent?: string;
    children?: string[];
    color?: string;
}

async function wrap(s: string): Promise<string> {
    return s;
}

async function workerImage(workerId?: string): Promise<string> {
    return workerId ? await cache.getPortrait(workerId) : await wrap(unknownUserImage)
}

interface Milestone {
    id: string;
    name: string;
    deadline: number;
    start: number;
    color?: string;
}

let WASM_INIT = false;

function chartIsHealthy(chart: Chart): boolean {
    try {
        return healthy(chart);
    } catch (error) {
        return false;
    }
}

function destroyChart(chart: Chart): void {
    try {
        destroy(chart);
    } catch (error) {
        // Ignore
    }
}

function loadImage(url: string): Promise<[Uint8Array, number, number]> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            if (!ctx) {
                reject(new Error("Failed to create canvas context"));
                return;
            }
            ctx.drawImage(img, 0, 0);
            const data = ctx.getImageData(0, 0, img.width, img.height);
            resolve([new Uint8Array(data.data), img.width, img.height]);
        };
        img.onerror = (error) => {
            reject(error);
        };
        img.crossOrigin = "anonymous";
        img.src = url;
    });
}

interface ChartLink {
    id: string;
    linkType: string;
}

async function rerunChart(planId: string): Promise<ChartPlan> {
    return await apiFetch(`/plan/${planId}/replan`, { method: "PUT" });
}

function titlesFromTask(state: ChartTask): string[] {
    if (state.titleSummaries.length > 0) {
        return state.titleSummaries;
    }
    return [state.slug];
}

function createGanttTask(state: ChartTask, now: number): Task {
    const inputDate = new Date(state.expectedStart + 'Z');
    const inputDifference = inputDate.getTime() - now;
    const inputDays = Math.floor(inputDifference / (24 * 60 * 60 * 1000));

    const endDifference = (inputDate.getTime() + state.expectedDuration) - now;
    const endDays = Math.floor(endDifference / (24 * 60 * 60 * 1000));

    let width = endDays - inputDays;
    if (width < 0) {
        width = 1;
    }

    const dependencies = state.dependencies.map((link) => {
        return { id: link, linkType: "dependsOn" };
    });
    const titles = titlesFromTask(state);

    return {
        id: state.taskId,
        row: state.row,
        slug: state.slug,
        titles,
        priority: state.priority,
        attention: state.attention,
        requirements: [],
        segments: state.segments,
        assigned: state.assignee ? state.assignee.id : "",

        actualStart: state.actualStart ? new Date(state.actualStart + 'Z').getTime() - now : undefined,
        actualDuration: state.actualDuration ? state.actualDuration : undefined,
        dueDate: state.segments[0].start + state.segments[0].duration,
        dependencies,
        open: state.state !== "closed",
    };
}

function GanttChart() {
    const { id } = useParams();
    const { loggedInUser, mobile } = useAppContext();

    const canvas = useRef<HTMLCanvasElement>(null);
    const navigate = useNavigate();
    const { theme } = useAppContext();
    const { getChart, updateChartTask } = useChartContext();
    const { milestones } = useMilestoneContext();

    const [ganttTasks, setGanttTasks] = useState<Task[]>([]);
    const [workerConstraints, setWorkerConstraints] = useState<Record<string, Constraint[]>>({});
    const [workerNames, setWorkerNames] = useState<Record<string, string>>({});
    const [chartStartTime, setChartStartTime] = useState(0);

    const [chartData, setChartData] = useState<null | ChartPlan>(null);

    const updateFromChartPlan = useCallback((chartPlan: ChartPlan) => {
        setChartData(chartPlan);
        if (!chartPlan.completed) {
            return;
        }
        const ganttTasks = chartPlan.tasks.map((task) => createGanttTask(task, chartStartTime))
        setGanttTasks(ganttTasks);
        setWorkerConstraints(chartPlan.workers.workers);
        setWorkerNames(chartPlan.workers.usernames);
    }, [chartStartTime]);

    useEffect(() => {
        if (!id) {
            return;
        }
        const now = new Date();
        now.setHours(0, 0, 0, 0);
        const nowMillis = now.getTime();
        setChartStartTime(nowMillis);

        getChart(id).then((chartPlan) => {
            updateFromChartPlan(chartPlan);
            const ganttMilestones = milestones.map((milestone) => {
                console.log("Milestone", milestone);
                if (!milestone.dueDate) {
                    return null;
                }

                const dueDate = new Date(milestone.dueDate + 'Z');
                const dueDateDifference = dueDate.getTime() - nowMillis;
                const dueDateDays = Math.floor(dueDateDifference / (24 * 60 * 60 * 1000));

                let startDateDay = 0;
                if (milestone.startDate) {
                    const startDate = new Date(milestone.startDate + 'Z');
                    const startDateDifference = startDate.getTime() - nowMillis;
                    startDateDay = Math.floor(startDateDifference / (24 * 60 * 60 * 1000));
                }

                return {
                    id: milestone.id,
                    name: milestone.name,
                    deadline: dueDateDays,
                    start: startDateDay,
                    color: 'limegreen',
                };
            }).filter((milestone) => milestone !== null) as Milestone[];
            setGanttMilestones(ganttMilestones);
        });
    }, [id, getChart, milestones, updateFromChartPlan]);

    const [ganttMilestones, setGanttMilestones] = useState<Milestone[]>([]);
    const [chart, setChart] = useState<null | Chart>();

    useEffect(() => {
        if (WASM_INIT) {
            return;
        }
        init().then(() => {
            WASM_INIT = true;
        });
    }, []);

    const onClickRerunChart = useCallback(() => {
        if (!id) {
            return;
        }
        setChartStartTime(new Date().getTime());
        rerunChart(id).then(updateFromChartPlan);
    }, [updateFromChartPlan, id]);

    const onClickVisitWorker = useCallback((workerId: string) => {
        navigate(`/app/users/${workerId}`);
    }, [navigate]);

    const onClick = useCallback((taskId: string) => {
        const task = ganttTasks.find((task) => task.id === taskId);
        if (task) {
            navigate(`/app/tasks/id/${task.slug}`);
        }
    }, [ganttTasks, navigate]);

    const onMoveTask = useCallback((taskId: string, segments: EventSegment[]) => {
        if (!id) {
            return;
        }
        const task = ganttTasks.find((task) => task.id === taskId);
        if (!task) {
            return;
        }
        const today = new Date(chartStartTime).toISOString();
        updateChartTask(id, taskId, { "segments": { segments, today, units: "days" } });
        console.log(`Moving Task(${taskId}) ${segments}`);
        task.segments = segments;
    }, [ganttTasks, id, chartStartTime, updateChartTask]);

    const onReassignTask = useCallback((taskId: string, workerId: string) => {
        if (!id) {
            return;
        }
        const task = ganttTasks.find((task) => task.id === taskId);
        if (!task) {
            return;
        }
        updateChartTask(id, taskId, { "assignee": workerId });
        const previousWorker = task.assigned;
        console.log(`Reassigning Task(${taskId}) ${previousWorker} -> ${workerId}`);
        task.assigned = workerId;
    }, [ganttTasks, id, updateChartTask]);

    useEffect(() => {
        let newChart: Chart | null = null;
        if (!chartData || !chartData.completed) {
            return;
        }

        if (WASM_INIT && canvas.current) {
            const sortedTasks = [];
            const images: Record<string, Promise<string>> = {};
            for (const task of ganttTasks) {
                const color = task.color ? getRgbColor(task.color) : undefined;
                sortedTasks.push({
                    ...task,
                    color,
                });
                if (!images[task.assigned]) {
                    images[task.assigned] = workerImage(task.assigned);
                }
            }
            sortedTasks.sort((a, b) => a.row - b.row);

            const sortedMilestones = [];
            for (const milestone of ganttMilestones) {
                const color = milestone.color ? getRgbColor(milestone.color) : undefined;
                sortedMilestones.push({
                    ...milestone,
                    color,
                });
            }
            sortedMilestones.sort((a, b) => a.start - b.start);

            console.log("Drawing chart", sortedTasks, workerConstraints, sortedMilestones);
            const can_edit_chart = isOrgPlanner(loggedInUser);

            const now = new Date(chartStartTime).toISOString();
            newChart = draw(
                canvas.current,
                sortedTasks,
                workerConstraints,
                workerNames,
                now,
                sortedMilestones,
                can_edit_chart,
                onClick,
                onMoveTask,
                onReassignTask,
                onClickVisitWorker,
                { styles: { theme } }
            );
            setChart(newChart);

            for (const key in images) {
                const image = images[key];
                image.then((img) => {
                    loadImage(img).then(([img, width, height]) => {
                        if (!newChart) {
                            return;
                        }
                        newChart.attach_image_to_worker(key, img, width, height);
                    }).catch((error) => {
                        console.error("Failed to load image", error);
                    });
                });
            }
        }
        return () => {
            if (newChart) {
                console.log("Clearing chart");
                destroyChart(newChart);
                setChart(null);
            }
        }
    }, [canvas,
        loggedInUser,
        ganttTasks,
        milestones,
        chartStartTime,
        theme,
        chartData,
        ganttMilestones,
        workerConstraints,
        workerNames,
        onClick,
        onMoveTask,
        onReassignTask,
        onClickVisitWorker]);

    let innerDisplay = <div className="w-full h-full"><Spinner /></div>;

    if (chartData) {
        innerDisplay = !chartData.completed ? (
            <div className="description__container">
                <h1> Project planning ongoing </h1>
                <br />
                <p> After the plan is complete you will see it here.</p>
                <br />
                <Link to="/app/charts">Go back to plans</Link>
            </div>
        ) : (<div className="w-full h-full">
            {!mobile && <Label className="ml-2 mt-2 flex"><FaArrowLeft /><FaArrowRight /><FaArrowUp /><FaArrowDown /><span className="ml-2">Use arrow keys to move chart</span></Label>}
            <div className={ganttChartStyle}>
                <canvas
                    ref={canvas}
                    height={window.innerHeight - 80}
                    width={window.innerWidth}
                />
            </div>
            <Button className="btn m-2" onClick={onClickRerunChart}><FaRecycle className='mr-2' />Rerun Plan</Button>
        </div>);
    }

    if (chart && !chartIsHealthy(chart)) {
        console.log("Chart is unhealthy, destroying");
        destroyChart(chart);
        setChart(null);
    }

    return innerDisplay;
}

export default GanttChart;
