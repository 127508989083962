"use client"

import * as React from "react"
import { DayPicker } from "react-day-picker"

import { cn } from "@/lib/utils"

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({
    className,
    classNames,
    showOutsideDays = true,
    ...props
}: CalendarProps) {
    return (
        <DayPicker
            showOutsideDays={showOutsideDays}
            className={cn("panel dark:text-white p-3", className)}
            classNames={{
                month_grid: "bg-neutral-100 dark:bg-neutral-700",
                month: "bg-cerulean-600 rounded-2xl dark:bg-aquamarine-400",
                caption_label: "m-auto text-neutral-100 dark:text-neutral-900 text-center items-center",
                today: "rounded-full text-accent dark:bg-accent",
                selected: "bg-neutral-200 dark:bg-neutral-600 text-white text-sm",
                day: "h-9 w-9 text-center text-sm p-0 relative",
                range_start: "rounded-l-md rounded-r-none h-9 w-9 bg-neutral-300 dark:bg-neutral-900 text-center text-sm p-0 relative",
                range_end: "rounded-r-md rounded-l-none h-9 w-9 text-center bg-neutral-300 dark:bg-neutral-900 text-sm p-0 relative",
                range_middle: "bg-neutral-200 dark:bg-neutral-800 text-neutral-500 dark:text-white",
                chevron: "bg-neutral-50 shadow-black shadow-sm text-center items-center rounded-full",
                ...classNames,
            }}
            {...props}
        />
    )
}
Calendar.displayName = "Calendar"

export { Calendar }
