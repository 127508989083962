import { createRef, useEffect, useRef, useState } from 'react';
import { FaXmark } from 'react-icons/fa6';
import { FaEdit } from 'react-icons/fa';

import Floater from '@/components/Floater';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover';
import { getFont, getTextWidth } from '@/tools';
import { eventEmitter, useAppContext } from '@/contexts';

interface DetailCardProps {
    children?: React.ReactNode;
    title: string | undefined;
    titleSummaries?: string[];
    titleIcon: JSX.Element;
    editable?: boolean;
    onSetTitle?: (title: string) => void;
    onClose?: () => void;
}

const DetailCard = ({ children, title, titleSummaries, titleIcon, editable, onSetTitle, onClose }: DetailCardProps) => {
    const { width } = useAppContext();
    const [titleText, setTitleText] = useState(title || "");
    const [popoverOpen, setPopoverOpen] = useState(false);

    useEffect(() => {
        const onEditTitle = () => setPopoverOpen(true);
        eventEmitter.on("EDIT-TITLE", onEditTitle);

        return () => {
            eventEmitter.off("EDIT-TITLE", onEditTitle);
        }
    }, []);

    const titleRef = useRef<HTMLHeadingElement>(null);
    const [titleHtml, setTitleHtml] = useState(<h2 ref={titleRef} className="card-detail__header">{title}</h2>);
    const topBarRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        console.log("title", title, titleSummaries, topBarRef.current, titleRef.current);
        if (topBarRef.current && titleRef.current) {
            const font = getFont(titleRef.current);
            const topBarWidth = topBarRef.current.clientWidth;
            const widthSpace = topBarWidth - 80;
            const titles: string[] = [];
            if (title) {
                titles.push(title);
            }
            if (titleSummaries) {
                titles.push(...titleSummaries);
            }

            let width;
            let usedTitle;

            for (let i = 0; i < titles.length; i++) {
                width = getTextWidth(titles[i], font);
                usedTitle = <h2 ref={titleRef} className="ml-2 text-left font-bold">{titles[i]}</h2>
                if (width < widthSpace) {
                    break;
                }
            }
            console.log("width", width, widthSpace, usedTitle, titles);
            if (usedTitle && width) {
                setTitleHtml(usedTitle);
            }
        }
    }, [title, titleSummaries, width]);

    useEffect(() => {
        setTitleText(title || "");
        setPopoverOpen(false);
    }, [title]);

    const popOverRef = createRef<HTMLButtonElement>();
    const onChangeTitle = () => {
        if (onSetTitle) {
            onSetTitle(titleText);
        }
        setPopoverOpen(false);
    };

    function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Escape") {
            setTitleText(title || "");
            setPopoverOpen(false);
        } else if (e.key === "Enter") {
            e.preventDefault();
            onChangeTitle();
        }
    }

    return (<div className="card-detail" >
        <div ref={topBarRef} className="card-detail__top-bar">
            {titleIcon}
            <div className="flex-grow" />
            {titleHtml}
            {editable && <Floater content="Edit title">
                <Popover open={popoverOpen} onOpenChange={setPopoverOpen}>
                    <PopoverTrigger ref={popOverRef} onClick={() => setPopoverOpen(!popoverOpen)}>
                        <div className="ml-2 card__icon">
                            <FaEdit className="mt-1" size={16} />
                        </div>
                    </PopoverTrigger>
                    <PopoverContent className="bg-neutral-50 dark:bg-neutral-900 w-[450px] mr-4">
                        <div className="grid grid-cols-4 gap-2 items-center">
                            <Label>Name</Label>
                            <Input className="col-span-3" value={titleText} onKeyDown={handleKeyDown} onChange={(e) => setTitleText(e.target.value)} />
                            <div /><div /><div /><Button className="btn mt-2" onClick={onChangeTitle}>Save</Button>
                        </div>
                    </PopoverContent>
                </Popover>
            </Floater>}
            <div className="flex-grow" />
            {onClose && <div className="card-detail__close" onClick={onClose}>
                <FaXmark />
            </div>}
        </div>
        {children}
    </div>)
}

export default DetailCard;
