import React from "react";
import AppPage from "./AppPage";
import { fetchNotifications, markAllNotificationsRead, Notification } from "./Notification";
import { isDevelopment, useMilestoneContext, useProjectsContext, useTasksContext, useUsersContext } from "../contexts";
import ReactTimeAgo from "react-time-ago";
import { useNavigate } from "react-router-dom";
import { priorityNameFromPriorityValues, priorityValuesToPriorityArray } from "./cards/task/Priority";

const NotificationItem: React.FC<{ notification: Notification }> = ({ notification }) => {
    const { getTask } = useTasksContext();
    const { milestones } = useMilestoneContext();
    const { priorityValues } = useProjectsContext();
    const { users } = useUsersContext();

    const highlightClass = notification.read ? "" : " notification__item--highlight";
    const date = new Date(notification.created + "Z");
    const navigate = useNavigate();

    const [message, setMessage] = React.useState<JSX.Element | null>(null);
    const [link, setLink] = React.useState<string | null>(null);

    React.useEffect(() => {
        if (notification.channel === "invite" && typeof notification.message === "object" && "orgId" in notification.message) {
            setMessage(<span>You have been invited to join {notification.message.orgName} by {notification.message.invitedBy.username}</span>);
            setLink("/app/invite");
        } else if (notification.channel === "taskChange" && typeof notification.message === "object" && "assignment" in notification.message) {
            const taskId = notification.message.assignment.taskId;
            const userId = notification.message.assignment.from;
            getTask(taskId).then((task) => {
                if (!task) {
                    console.error("Task not found", taskId);
                    return;
                }
                const user = users.find((user) => user.id === userId);

                setMessage(<span>{user?.username} assigned you to {task?.title}</span>);
                setLink("/app/tasks/id/" + task?.slug);
            });
        } else if (notification.channel === "taskChange" && typeof notification.message === "object" && "comment" in notification.message) {
            const taskId = notification.message.comment.taskId;
            const userId = notification.message.comment.from;
            getTask(taskId).then((task) => {
                if (!task) {
                    console.error("Task not found", taskId);
                    return;
                }
                const user = users.find((user) => user.id === userId);

                setMessage(<span>{user?.username} commented on {task?.title}</span>);
                setLink("/app/tasks/id/" + task?.slug);
            });
        } else if (notification.channel === "taskChange" && typeof notification.message === "object" && "update" in notification.message) {
            const taskId = notification.message.update.taskId;
            const userId = notification.message.update.from;
            const update = notification.message.update.update;

            getTask(taskId).then((task) => {
                if (!task) {
                    console.error("Task not found", taskId);
                    return;
                }
                const user = users.find((user) => user.id === userId);

                let updateString = <span>updated {task.slug}: {update.toString()}</span>;

                if (typeof update === "object" && "assignOther" in update) {
                    const userId = update.assignOther.userId;
                    const user = users.find((user) => user.id === userId);
                    updateString = <span>assigned {task.slug} to {user?.username}</span>;
                } else if (typeof update === "object" && "changeDescription" in update) {
                    updateString = <span>modified the description for {task.slug}</span>;
                } else if (typeof update === "object" && "changeTitle" in update) {
                    updateString = <span>set the title of {task.slug} to {update.changeTitle.title}</span>;
                } else if (typeof update === "object" && "changePriority" in update) {
                    if (priorityValues) {
                        const priorities = priorityValuesToPriorityArray(priorityValues);
                        const name = priorityNameFromPriorityValues(update.changePriority.value, priorities);
                        updateString = <span>changed the priority of {task.slug} to {name}</span>;
                    }
                } else if (typeof update === "object" && "changeDueDate" in update) {
                    if (update.changeDueDate.dueDate === null) {
                        updateString = <span>removed the due date of {task.slug}</span>;
                    } else {
                        const date = new Date(update.changeDueDate.dueDate);
                        updateString = (
                            <>
                                <span>set the due date of {task.slug} to </span>
                                <ReactTimeAgo className="ml-2" date={date} />
                            </>
                        );
                    }
                } else if (typeof update === "object" && "changeMilestone" in update) {
                    if (update.changeMilestone.milestoneId) {
                        const milestone = milestones.find(m => m.id === update.changeMilestone.milestoneId);
                        updateString = <span>changed the milestone of {task.slug} to {milestone?.name}</span>;
                    } else {
                        updateString = <span>removed the milestone from {task.slug}</span>;
                    }
                } else if (typeof update === "object" && "addComment" in update) {
                    if (update.addComment.parentId) {
                        updateString = <span>replied to a comment on {task.slug}</span>;
                    } else {
                        updateString = <span>added a comment to {task.slug}</span>;
                    }
                } else if (typeof update === "object" && "editComment" in update) {
                    updateString = <span>edited a comment on {task.slug}</span>;
                } else if (typeof update === "object" && "removeComment" in update) {
                    updateString = <span>deleted a comment from {task.slug}</span>;
                } else if (typeof update === "object" && "link" in update) {
                    getTask(update.link.taskId).then((linkedTask) => {
                        updateString = <span>linked {task.slug} to task {linkedTask?.slug} ({update.link.linkType})</span>;
                    });
                } else if (typeof update === "object" && "unlink" in update) {
                    getTask(update.unlink.taskId).then((linkedTask) => {
                        updateString = <span>unlinked {task.slug} from task {linkedTask?.slug}</span>;
                    });
                } else if (typeof update === "object" && "metadata" in update) {
                    if ("component" in update.metadata.value) {
                        updateString = <span>added component {update.metadata.value.component as string} from {task.slug}</span>;
                    } else if ("label" in update.metadata.value) {
                        updateString = <span>removed tag {update.metadata.value.label as string} from {task.slug}</span>;
                    }
                } else if (typeof update === "object" && "removeMetadata" in update) {
                    if ("component" in update.removeMetadata.value) {
                        updateString = <span>removed component {update.removeMetadata.value.component as string} from {task.slug}</span>;
                    } else if ("label" in update.removeMetadata.value) {
                        updateString = <span>removed tag {update.removeMetadata.value.label as string} from {task.slug}</span>;
                    }
                } else if (typeof update === "object" && "transition" in update) {
                    updateString = <span>transitioned {task.slug} to in progress</span>;
                } else if (typeof update === "string") {
                    switch (update) {
                        case "assignSelf":
                            updateString = <span>assigned themself to {task.slug}</span>;
                            break;
                        case "stopWatchingTask":
                            updateString = <span>stopped watching {task.slug}</span>;
                            break;
                        case "close":
                            updateString = <span>closed {task.slug}</span>;
                            break;
                        case "restart":
                            updateString = <span>reopened {task.slug}</span>;
                            break;
                        case "archive":
                            updateString = <span>archived {task.slug}</span>;
                            break;
                        case "unassign":
                            updateString = <span>unassigned {task.slug}</span>;
                            break;
                        case "undo":
                            updateString = <span>undid the last action on {task.slug}</span>;
                            break;
                        case "watchTask":
                            updateString = <span>started watching {task.slug}</span>;
                            break;
                        default:
                            updateString = <span>updated {task.slug}</span>;
                            break;
                    }
                } else {
                    updateString = <span>updated {task.slug}</span>;
                }

                setMessage(<div>{user?.username} {updateString}</div>);
                setLink("/app/tasks/id/" + task?.slug);
            });
        } else if (notification.channel === "result" && typeof notification.message === "string") {
            setMessage(<span>notification.message</span>);
        } else if (notification.channel === "test") {
            setMessage(<span>Test message</span>);
        }
    }, [notification, getTask, milestones, priorityValues, users]);

    const onClickNotification = () => {
        if (link) {
            navigate(link);
        }
    }

    return (
        <div className={"notification__item" + highlightClass} onClick={onClickNotification}>
            <div className="notification__message">{message}</div>
            <ReactTimeAgo className="ml-2 text-sm" date={date} />
        </div>
    );
};

export default function NotificationPage() {
    const [notifications, setNotifications] = React.useState<Notification[]>([]);

    React.useEffect(() => {
        if (isDevelopment) {
            const Bill = {
                id: "1",
                created: "1954-01-01",
                username: "Bill Bezos",
                email: "bill@bees.biz",
                jobTitle: "Beemaster",
            };
            setNotifications([
                { id: "1", channel: "invite", message: { orgName: "Bill's Bees", orgId: "2", invitedBy: Bill }, read: false, created: "2024-01-01T00:00:00" },
                { id: "2", channel: "test", message: { orgName: "Bill's Knees", orgId: "4", invitedBy: Bill }, read: true, created: "2023-12-01T11:59:59" },
            ]);
            return;
        }

        fetchNotifications(1).then((notifications) => {
            setNotifications(notifications);
            markAllNotificationsRead();
        });
    }, []);

    return (
        <AppPage active="notifications">
            <div className="flex-col ml-auto mr-auto p-2">
                <h1 className="mb-4 w-full text-center">Notifications</h1>
                <div className="notification__container">
                    {notifications.map((notification) => (
                        <NotificationItem key={notification.id} notification={notification} />
                    ))}
                </div>
            </div>
        </AppPage>
    );
}
