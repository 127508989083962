import AppContextProvider from "../contexts/AppContext";
import NotificationPage from "./NotificationPage";
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

// Payments
import UpgradePage from "./UpgradePage";
import PurchasePage from "./PurchasePage";
import CheckoutPage from "./CheckoutPage";

// Organization data
import ChartWindow from "./cards/chart/ChartWindow";
import DataWindow from "./cards/data/DataWindow";
import MilestoneWindow from "./cards/milestone/MilestoneWindow";
import ProjectWindow from "./cards/project/ProjectWindow";
import TaskWindow from "./cards/task/TaskWindow";
import UserWindow from "./cards/user/UserWindow";
import UserSettings from "./cards/user/UserSettings";
import WorklogPage from "./WorklogPage";
import WorkflowPage from "./WorkflowPage";
import { InstructionsPage } from "./Instructions";

// Email links
import InvitePage from "./InvitePage";
import { BetaSignupPage, BetaInvitePage } from "./BetaInvitePage";
import VerifyPage from "./VerifyPage";
import ServerDown from "./ServerDown";

// Onboarding
import OnboardingPage from "./OnboardingPage";

// Import apps
import GithubImportPage, { GithubImportLogin } from "./GithubImportPage";

// Admin pages
import Admin from "./Admin";

const router = createBrowserRouter([
    { path: "/", element: <TaskWindow /> },
    { path: "/app/charts/*", element: <ChartWindow /> },
    { path: "/app/data/*", element: <DataWindow /> },
    { path: "/app/milestones/*", element: <MilestoneWindow /> },
    { path: "/app/down", element: <ServerDown /> },
    { path: "/app/flows", element: <WorkflowPage /> },
    { path: "/app/notifications", element: <NotificationPage /> },
    { path: "/app/projects/*", element: <ProjectWindow /> },
    { path: "/app/settings", element: <UserSettings /> },
    { path: "/app/tasks/*", element: <TaskWindow /> },
    { path: "/app/users/*", element: <UserWindow /> },
    { path: "/app/worklog", element: <WorklogPage /> },
    { path: "/app/chat", element: <InstructionsPage /> },

    // Email links
    { path: "/app/invite", element: <InvitePage /> },
    { path: "/app/beta-invite", element: <BetaInvitePage /> },
    { path: "/app/verify-email", element: <VerifyPage /> },

    // Payments
    { path: "/app/upgrade", element: <UpgradePage /> },
    { path: "/app/checkout/complete", element: <PurchasePage /> },
    { path: "/app/checkout", element: <CheckoutPage /> },

    // Onboarding
    { path: "/app/onboarding", element: <OnboardingPage /> },
    { path: "/app/beta", element: <BetaSignupPage /> },

    // Import apps
    { path: "/app/login/github", element: <GithubImportLogin /> },
    { path: "/app/import/github", element: <GithubImportPage /> },

    // Admin pages
    { path: "/app/admin", element: <Admin /> },
]);

function App() {
    return (
        <AppContextProvider>
            <RouterProvider router={router} />
        </AppContextProvider>
    );
}

export default App;
