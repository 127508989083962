import { ChevronsDown, Lightbulb, Milestone, Terminal } from "lucide-react";
import { TbReport } from "react-icons/tb";
import {
    FaCircleChevronRight,
    FaCircleCheck,
    FaCircleXmark,
    FaDiagramProject,
} from "react-icons/fa6";
import { FaDatabase, FaPencilAlt, FaProjectDiagram, FaRegChartBar, FaTasks, FaUsers } from "react-icons/fa";
import React, { useEffect, useState, useImperativeHandle, useCallback } from "react";

import { cache, useAppContext, useInstructionContext, useTasksContext } from "@/contexts";
import { TaskData } from "@/contexts/Task";
import { ConsentRequest, Message, Consent, ConsentTask, ConsentProject, ConsentTaskUpdateWrapper, Tip, TipPayload } from "@/contexts/InstructionContext";
import AppPage from "@/components/AppPage";
import Spinner from "@/components/Spinner";
import assistantImage from "@/assets/SUBSEQ-logo.png";
import errorImage from "@/assets/error.webp";
import defaultUserImage from "@/assets/protag.webp";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
import {
    Sheet,
    SheetClose,
    SheetHeader,
    SheetOverlay,
    SheetPortal,
    SheetPrimitive,
    sheetVariants,
} from "@/components/ui/sheet"
import { cn } from "@/lib/utils";
import Markdown from "react-markdown";

interface MessageDisplay {
    portrait: string;
    text: string;
}

function ErrorMessage({ portrait, text }: MessageDisplay) {
    return (
        <div className="flex my-10 mx-1 p-4 border border-red-500 dark:border-red-500 rounded-lg">
            <img src={portrait} className="personelle__img" />
            <div className="my-3">{text}</div>
        </div>
    );
}

function LeftMessage({ portrait, text }: MessageDisplay) {
    return (
        <div className="flex">
            <img src={portrait} className="personelle__img mr-2" />
            <Markdown className="instructions__left-text">{text}</Markdown>
        </div>
    );
}

function RightMessage({ portrait, text }: MessageDisplay) {
    return (
        <div className="flex">
            <div className="instructions__right-text">{text}</div>
            <img src={portrait} className="personelle__img" />
        </div>
    );
}

interface TaskConsentPanelProps {
    task: ConsentTask;
    onAccept(content: string): void;
    onReject(): void;
}

function TaskConsentPanel({
    task,
    onAccept,
    onReject,
}: TaskConsentPanelProps) {
    const acceptString = `Created ${task.title}`;
    return (
        <div className="flex">
            <div className="instructions__card m-1 flex-col w-full">
                <div className="card__top-bar items-center w-full rounded-lg">
                    <div className="card__icon">
                        <FaTasks />
                    </div>
                    <h2 className="card-header">{task.title}</h2>
                </div>
                <div className="detail-grid__text max-h-24 text-sm">{task.description}</div>
                <AcceptRejectButtons onAccept={() => onAccept(acceptString)} onReject={onReject} />
            </div>
        </div>
    );
}

interface TaskUpdatePanelProps {
    update: ConsentTaskUpdateWrapper;
    onAccept(content: string): void;
    onReject(): void;
}

function TaskUpdatePanel({
    update,
    onAccept,
    onReject,
}: TaskUpdatePanelProps) {
    const { getTask } = useTasksContext();
    const [task, setTask] = useState<TaskData | null>(null);
    useEffect(() => {
        getTask(update.task.id).then(setTask);
    }, [update.task.id, getTask]);
    const name = task ? task.title : update.task.id;
    const acceptString = `Updated ${name}`;

    return (
        <div className="flex">
            <div className="instructions__card m-1 flex-col w-full">
                <div className="card__top-bar items-center w-full rounded-lg">
                    <div className="card__icon">
                        <FaTasks />
                    </div>
                    <h2 className="card-header">{name}</h2>
                </div>
                <div className="detail-grid__text max-h-24 text-sm">{update.task.description}</div>
                <div className="detail-grid__text text-sm">Update this task?</div>
                <AcceptRejectButtons onAccept={() => onAccept(acceptString)} onReject={onReject} />
            </div>
        </div>
    );
}

interface ProjectConsentPanelProps {
    project: ConsentProject;
    onAccept(content: string): void;
    onReject(): void;
}

interface AcceptRejectButtonsProps {
    onAccept(): void;
    onReject(): void;
    acceptString?: string;
    rejectString?: string;
    className?: string;
}

function AcceptRejectButtons({ onAccept, onReject, acceptString, rejectString, className }: AcceptRejectButtonsProps) {
    const acceptText = acceptString || "Accept";
    const rejectText = rejectString || "Reject";
    return <div className={cn("flex mt-2", className)}>
        <Button
            className="p-2 transition-colors duration-500 btn"
            onClick={onAccept}
        >
            <FaCircleCheck className="cursor-pointer" />
            <Label className="ml-1 cursor-pointer">{acceptText}</Label>
        </Button>
        <Button className="rounded-3xl transition-colors duration-500 bg-red-500 hover:bg-netural-red-700 dark:bg-hellotrope-400 dark:hover:bg-neutral-100 text-white dark:text-black ml-auto" onClick={onReject}>
            <FaCircleXmark className="cursor-pointer" />
            <Label className="ml-1 cursor-pointer">{rejectText}</Label>
        </Button>
    </div>;
}

function ProjectConsentPanel({
    project,
    onAccept,
    onReject,
}: ProjectConsentPanelProps) {
    const acceptString = `Created ${project.title}`;
    return (
        <div className="flex">
            <div className="instructions__card m-1 flex-col w-full">
                <div className="card__top-bar items-center w-full rounded-lg">
                    <div className="card__icon">
                        <FaDiagramProject />
                    </div>
                    <h2 className="card-header">{project.title}</h2>
                </div>
                <div className="detail-grid__text text-sm">{project.description}</div>
                <AcceptRejectButtons onAccept={() => onAccept(acceptString)} onReject={onReject} />
            </div>
        </div>
    );
}

interface ConsentMessageProps {
    payload: ConsentRequest;
    onAccept(content: string): void;
    onReject(): void;
}

function TipJar({ visible, tips }: { visible: boolean, tips: Tip[] }) {
    if (!visible) {
        return null;
    }
    return (
        <div className="m-4 w-96 grid grid-cols-2 gap-4">
            {tips.map((tip) => (
                <TipMessage key={tip.tipId} message={tip.payload} />
            ))}
        </div>
    );
}

function TipMessage({ message }: { message: TipPayload }) {
    let icon = <Lightbulb className="mb-2" />;
    if (message.icon === "pen") {
        icon = <FaPencilAlt className="mb-2" />;
    } else if (message.icon === "plan") {
        icon = <FaRegChartBar className="mb-2" />;
    } else if (message.icon === "milestone") {
        icon = <Milestone className="mb-2" />;
    } else if (message.icon === "data") {
        icon = <FaDatabase className="mb-2" />;
    } else if (message.icon === "reports") {
        icon = <TbReport className="mb-2" />;
    } else if (message.icon === "team") {
        icon = <FaUsers className="mb-2" />;
    } else if (message.icon === "projects") {
        icon = <FaProjectDiagram className="mb-2" />;
    } else if (message.icon === "tasks") {
        icon = <FaTasks className="mb-2" />;
    } else if (message.icon === "console") {
        icon = <Terminal className="mb-2" />;
    }

    return <div className="instructions__tip">
        {icon}
        {message.content}
    </div>;
}

function ConsentMessage({ payload, onAccept, onReject }: ConsentMessageProps) {
    if ("task" in payload) {
        return <TaskConsentPanel
            task={payload.task}
            onAccept={onAccept}
            onReject={onReject}
        />;
    } else if ("taskUpdate" in payload) {
        return <TaskUpdatePanel
            update={payload.taskUpdate}
            onAccept={onAccept}
            onReject={onReject}
        />
    } else if ("project" in payload) {
        return <ProjectConsentPanel
            project={payload.project}
            onAccept={onAccept}
            onReject={onReject}
        />;
    } else {
        return null;
    }
}

interface InstructionMessageProps {
    message: Message;
    onAccept(content: string): void;
    onReject(): void;
}

function InstructionMessage({
    message,
    onAccept,
    onReject,
}: InstructionMessageProps) {
    const [userImage, setUserImage] = useState(defaultUserImage);
    const { loggedInUser } = useAppContext();

    useEffect(() => {
        if (loggedInUser === null) {
            return;
        }
        cache.getPortrait(loggedInUser.id).then((portrait) => {
            setUserImage(portrait);
        });
    }, [loggedInUser]);

    if ("consentId" in message) {
        return <ConsentMessage
            payload={message.payload}
            onAccept={onAccept}
            onReject={onReject} />;
    } else if ("tipId" in message) {
        return null;
    } else if ("content" in message) {
        if ("text" in message.content) {
            const text = message.content.text;
            if (message.role === "error") {
                return <ErrorMessage portrait={errorImage} text={text} />;
            } else if (message.role === "assistant") {
                return <LeftMessage portrait={assistantImage} text={text} />;
            } else if (message.role === "user") {
                return <RightMessage portrait={userImage} text={text} />;
            } else {
                return null;
            }
        } else {
            // TODO
            const obj = JSON.stringify(message.content.object);
            return <LeftMessage portrait={assistantImage} text={obj} />;
        }
    }
}


function instructionsRunning(messages: Message[]): boolean {
    if (messages.length === 0) {
        return false;
    }
    const lastMessage = messages[messages.length - 1];
    if ("role" in lastMessage) {
        return lastMessage.state === "ongoing";
    }
    return false;
}

interface ConsentIndexGroup {
    message: Consent;
    index: number;
}

const STICKY_THRESHOLD = 400; // px

interface InstructionMessagesProps {
    refocus: number;
}

function InstructionMessages({ refocus }: InstructionMessagesProps) {
    const { sendWebMessage, socketIsConnected } = useAppContext();
    const innerRef = React.useRef<HTMLDivElement>(null);
    const inputRef = React.useRef<InstructionInputBoxHandle>(null);
    const [highestScroll, setHighestScroll] = useState(0);

    const {
        messages,
        onAcceptConsent,
        onRejectConsent,
        onAcceptAllConsent,
        onRejectAllConsent } = useInstructionContext();
    const [showTips, setShowTips] = useState(true);

    const scrollToBottom = () => {
        innerRef.current?.scrollTo({
            top: innerRef.current.scrollHeight,
            behavior: 'smooth',
        });
    };

    useEffect(() => {
        setTimeout(() => {
            scrollToBottom();
            console.log("scrolling to bottom");
        }, 100);
    }, [refocus]);

    useEffect(() => {
        const observer = new MutationObserver(() => {
            if (innerRef.current?.scrollHeight || 0 > highestScroll) {
                if (isNearBottom() || highestScroll === 0) {
                    scrollToBottom();
                }
                setHighestScroll(innerRef.current?.scrollHeight || 0);
            }
        });
        observer.observe(innerRef.current!, {
            childList: true,
            subtree: true,
            characterData: true,
        });
        return () => observer.disconnect();
    }, [highestScroll]);

    const isNearBottom = () => {
        const { scrollHeight, scrollTop, clientHeight } = innerRef.current!;
        return scrollHeight - scrollTop - clientHeight < STICKY_THRESHOLD;
    }

    const onAcceptAll = useCallback(() => {
        onAcceptAllConsent();
        scrollToBottom();
    }, [onAcceptAllConsent]);

    const onRejectAll = useCallback(() => {
        onRejectAllConsent();
    }, [onRejectAllConsent]);

    function sendTextMessage(msg: string) {
        if (!socketIsConnected) {
            console.error("Websocket is not connected");
            return;
        }
        setShowTips(false);
        sendWebMessage({ instruct: { message: { msg: msg } } });
        inputRef.current?.clearText();
    }

    const isRunning = instructionsRunning(messages);
    const tips = messages.filter((msg) => "tipId" in msg).map((msg) => msg as Tip);
    const consentMessages: ConsentIndexGroup[] = messages
        .map((message, index) => { return { message: message as Consent, index }; })
        .filter(({ message }) => "consentId" in message);

    return (
        <>
            <div ref={innerRef} className="instructions__messages">
                <Button className="btn mx-auto" onClick={scrollToBottom}><ChevronsDown /></Button>
                {messages.map((message, index) => (
                    <InstructionMessage
                        key={index}
                        message={message}
                        onAccept={(msg) => onAcceptConsent(index, msg)}
                        onReject={() => onRejectConsent(index)}
                    />
                ))}
                {consentMessages.length > 1 && <><div className='h-4' /><AcceptRejectButtons className="mx-4" onAccept={onAcceptAll} onReject={onRejectAll} acceptString="Accept All" rejectString="Reject All" /></>}
                <TipJar visible={showTips} tips={tips} />
                {isRunning && <Spinner className="text-cerulean-600 dark:text-aquamarine-400" />}
            </div>
            <InstructionInputBox onSend={sendTextMessage} refocus={refocus} ref={inputRef} />
        </>);
}

interface InstructionInputBoxHandle {
    clearText(): void;
}

interface InputProps {
    onSend(msg: string): void;
    refocus: number;
}

const InstructionInputBox = React.forwardRef<InstructionInputBoxHandle, InputProps>(({ onSend, refocus }, ref) => {
    const [content, setContent] = useState("");
    const inputRef = React.useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
        clearText() {
            setContent("");
        }
    }));

    useEffect(() => {
        setTimeout(() => {
            inputRef.current?.focus();
            console.log("refocusing");
        }, 100);
    }, [inputRef, refocus]);

    function clickEventHandler() {
        onSend(content);
        setContent("");
    }

    function changeEventHandler(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target !== null) {
            const change = event.target.value;
            setContent(change);
        }
    }

    function handleEnter(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key == "Enter") {
            if (event.shiftKey) {
                const cursorPosition = event.currentTarget.selectionStart;
                if (cursorPosition !== null) {
                    const newText =
                        content.slice(0, cursorPosition) +
                        "\n" +
                        content.slice(cursorPosition);
                    setContent(newText);
                } else {
                    const newText = content + "\n";
                    setContent(newText);
                }
            } else {
                if (content.length > 0) {
                    clickEventHandler();
                }
            }
        }
    }

    return (
        <div className="instructions__input-box flex">
            <input
                ref={inputRef}
                onChange={changeEventHandler}
                onKeyDown={handleEnter}
                value={content}
                autoCapitalize="sentences"
                spellCheck={true}
                type="text"
                className="instructions__input"
                placeholder="Conversation..."
            ></input>
            <div onClick={clickEventHandler} className="mr-1">
                <FaCircleChevronRight />
            </div>
        </div>
    );
});

export function InstructionsPage() {
    return <AppPage active="instructions">
        <Instructions fullPage />
    </AppPage>;
}

interface InstructionsProps {
    fullPage?: boolean;
}

function Instructions({ fullPage = false }: InstructionsProps) {
    const [dataState, setDataState] = useState<"open" | "closed">("closed");
    const [rightDataState, setRightDataState_] = useState<"open" | "closed">("closed");
    const [rightIsVisible, setRightIsVisible] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [refocus, setRefocus] = useState(0);

    const { conversationOpen, setConversationOpen } = useInstructionContext();
    const isOpen = fullPage || conversationOpen;

    const setRightDataState = useCallback((state: "open" | "closed") => {
        if (state === "open") {
            setRightDataState_("open");
            setTimeout(() => setRightIsVisible(true), 100);
        } else {
            setRightDataState_("closed");
            setTimeout(() => setRightIsVisible(false), 200);
        }
    }, [setRightDataState_, setRightIsVisible]);

    const handleOpenClosed = useCallback((state: boolean) => {
        if (state) {
            setConversationOpen(state);
            setDataState("open");
            setRefocus(new Date().getTime());
            setTimeout(() => setIsVisible(state), 100);
        } else {
            setDataState("closed");
            setRightDataState("closed");
            setTimeout(() => {
                setIsVisible(state)
                setConversationOpen(state);
            }, 200);
        }
    }, [setConversationOpen, setDataState, setRightDataState]);

    useEffect(() => {
        handleOpenClosed(conversationOpen);
    }, [conversationOpen, handleOpenClosed]);

    const instructions = fullPage ? (
        <div className="instructions__container w-full p-4" >
            <div className="instructions">
                <InstructionMessages refocus={refocus} />
            </div>
        </div>
    ) : (
        <>
            <Sheet open={isOpen} onOpenChange={handleOpenClosed}>
                <SheetHeader>
                    <SheetClose />
                </SheetHeader>
                <SheetPortal>
                    <SheetOverlay />
                    <SheetPrimitive.Content className={isVisible ? "" : "hidden"}>
                        <>
                            <div
                                data-state={rightDataState}
                                hidden={!rightIsVisible}
                                className={cn(sheetVariants({ side: "right" }), `w-1/3 data-[state=${rightDataState}]`)}
                            >
                            </div>
                            <div data-state={dataState} className={cn(sheetVariants({ side: "left" }), "w-1/3")}>
                                <InstructionMessages refocus={refocus} />
                            </div>
                        </>
                    </SheetPrimitive.Content>
                </SheetPortal>
            </Sheet>
        </>
    );

    return instructions;
}
export default Instructions;
