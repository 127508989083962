import CreatableSelect from "react-select/creatable";
import { FaArrowDownLong } from "react-icons/fa6";
import { FaDotCircle } from "react-icons/fa";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ActionMeta, MultiValue } from "react-select";

import { TaskData, Tag, TagLabel, TagComponent } from "@/contexts/Task";
import { Release } from "@/contexts/Project";
import { UserData } from "@/contexts/User";
import reactSelectTheme from "@/components/SelectTheme";
import { useAppContext, useTasksContext } from "@/contexts";
import { InfoIcon } from "@/components/Floater";
import { PlannedTask } from "@/components/cards/task/PlannedTask";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from "@/components/ui/dropdownmenu";

export interface FlowNode {
    id: string;
    nodeName: string;
}

export interface TaskLink {
    link: TaskData;
    linkType: string;
}

export interface TaskExpandedData {
    metadata: object[];
    state: FlowNode;
    validTransitions: FlowNode[];
    watchers: UserData[];
    dependencies: TaskLink[];
    linksIn: TaskLink[];
    releases?: Release[];
    deployment?: string;
    plan?: PlannedTask;
}

interface LinkStateProps {
    outLinks: TaskLink[];
    inLinks: TaskLink[];
}

export function LinkState({ outLinks, inLinks }: LinkStateProps) {
    const { slugToTask } = useTasksContext();
    const hasLinks = outLinks.length > 0 || inLinks.length > 0;
    const navigate = useNavigate();

    const depends_on_link_class = "links__tag--dependsOn";
    const related_to_link_class = "links__tag--relatedTo";
    const subtask_of_link_class = "links__tag--subtaskOf";

    if (!hasLinks) {
        return null;
    }

    function openSlug(slug: string) {
        const task = slugToTask[slug];
        if (task) {
            navigate(`/app/tasks/id/${task.slug}`);
        }
    }

    return (<div className="links__graph">
        {inLinks.length > 0 && <div>
            <div id="in-link-box" className="links__container">
                {inLinks.map((link, index) => (
                    <div
                        id={`in-link-${index}`}
                        key={index}
                        className={link.linkType === "dependsOn" ?
                            depends_on_link_class : link.linkType === "relatedTo" ?
                                related_to_link_class : subtask_of_link_class}
                        title={link.linkType}
                        onClick={() => openSlug(link.link.slug)}
                    >
                        {link.link.slug}
                    </div>
                ))}
            </div>
            <FaArrowDownLong className="links__arrow" />
        </div>}
        <FaDotCircle className="links__dot" />
        {outLinks.length > 0 && <div>
            <FaArrowDownLong className="links__arrow" />
            <div id="out-link-box" className="links__container">
                {outLinks.map((link, index) => (
                    <div
                        id={`out-link-${index}`}
                        key={index}
                        className={link.linkType === "dependsOn" ?
                            depends_on_link_class : link.linkType === "relatedTo" ?
                                related_to_link_class : subtask_of_link_class}
                        title={link.linkType}
                        onClick={() => openSlug(link.link.slug)}
                    >
                        {link.link.slug}
                    </div>
                ))}
            </div>
        </div>}
    </div>);
}

interface Option {
    readonly value: string;
    readonly label: string;
}

interface TagStateProps {
    metadata: object[];
    handleAddTag: (tag: Tag) => void;
    handleDeleteTag: (tag: Tag) => void;
}

export function TagState({
    metadata,
    handleAddTag,
    handleDeleteTag,
}: TagStateProps) {
    const { theme, config } = useAppContext();
    const { selectTheme, classes } = useMemo(() => {
        return reactSelectTheme(theme, config);
    }, [theme, config]);

    const { projectTags, projectComponents } = useTasksContext();

    const tagElements: Option[] = metadata
        .filter((tag) => "label" in tag)
        .map((tag) => {
            const { label } = tag as TagLabel;
            return { value: label, label: label };
        });

    const componentElements: Option[] = metadata
        .filter((tag) => "component" in tag)
        .map((tag) => {
            const { component } = tag as TagComponent;
            return { value: component, label: component };
        });

    const onChangeTagGeneral = (actionMeta: ActionMeta<Option>, tag: Tag) => {
        if (actionMeta.action == "create-option" || actionMeta.action == "select-option") {
            handleAddTag(tag);
        } else if (actionMeta.action == "remove-value" || actionMeta.action == "deselect-option" || actionMeta.action == "pop-value") {
            handleDeleteTag(tag);
        }
    }

    const onChangeTag = (_option: MultiValue<Option> | null, actionMeta: ActionMeta<Option>) => {
        console.log(actionMeta);
        if (actionMeta.removedValue) {
            onChangeTagGeneral(actionMeta, { label: actionMeta.removedValue.value });
        } else if (actionMeta.option) {
            onChangeTagGeneral(actionMeta, { label: actionMeta.option.value });
        }
    }

    const onChangeComponent = (_option: MultiValue<Option> | null, actionMeta: ActionMeta<Option>) => {
        console.log(actionMeta);
        if (actionMeta.removedValue) {
            onChangeTagGeneral(actionMeta, { component: actionMeta.removedValue.value });
        } else if (actionMeta.option) {
            onChangeTagGeneral(actionMeta, { component: actionMeta.option.value });
        }
    }

    return (<div className="m-2 items-center justify-between rounded-lg border p-4">
        <div className="flex ml-2">
            <h5>Tags</h5>
            <div className="flex-grow" />
            <InfoIcon className="ml-2 mt-2 omnibar__icon text-sm" content="Tags can help find tasks later" />
        </div>
        <div className="tag__container">
            <CreatableSelect
                isMulti
                value={tagElements}
                options={projectTags}
                isClearable={false}
                classNames={classes as object}
                theme={selectTheme}
                onChange={onChangeTag} />
        </div>
        <div className="flex ml-2">
            <h5>Components</h5>
            <div className="flex-grow" />
            <InfoIcon className="ml-2 omnibar__icon mt-2 text-sm" content="Components will assist telling the scheduler who should be assigned to a task" />
        </div>
        <div className="tag__container">
            <CreatableSelect
                isMulti
                value={componentElements}
                options={projectComponents}
                isClearable={false}
                classNames={classes as object}
                theme={selectTheme}
                onChange={onChangeComponent} />
        </div>
    </div>);
}

interface TaskStateProps {
    state: FlowNode;
    validTransitions: FlowNode[];
    handleSetState: (flowNodeId: string, nodeName: string) => void;
}

function TaskState({
    state,
    validTransitions,
    handleSetState,
}: TaskStateProps) {
    const [showOptions, setShowOptions] = useState(false);

    useEffect(() => {
        setShowOptions(false);
    }, [state]);

    function toggleShowOptions() {
        setShowOptions(!showOptions);
    }

    function handleNextState(transitionState: FlowNode) {
        handleSetState(transitionState.id, transitionState.nodeName);
    }

    return (<div className="m-2 p-4 border items-center justify-between rounded-lg">
        {validTransitions.length > 0 ? <DropdownMenu>
            <DropdownMenuTrigger className="w-full">
                <div className="state__name" onClick={toggleShowOptions}>
                    {state.nodeName}
                </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-full panel">
                <DropdownMenuLabel>Transitions</DropdownMenuLabel>
                {validTransitions.map((transitionState) => (
                    <DropdownMenuItem
                        key={transitionState.id}
                        className="state__dropdown"
                        onClick={() => handleNextState(transitionState)}
                    >
                        {transitionState.nodeName}
                    </DropdownMenuItem>
                ))}
            </DropdownMenuContent>
        </DropdownMenu>
            :
            <div className="state__name--end" onClick={toggleShowOptions}>
                {state.nodeName}
            </div>
        }
    </div>);
}
export default TaskState;
