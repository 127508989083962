import React from 'react';
import { FaSortAlphaDown, FaCalendarDay, FaCalendarPlus, FaSortAmountDown } from "react-icons/fa";
import { useDrop, DropTargetMonitor } from 'react-dnd';

import { CardData } from '@/components/cards/CardDataInterfaces';
import { useTasksContext } from '@/contexts';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip';

interface UtilityCardProps {
    handleCardClick?: () => void;
    handleCardDrop?: (card: CardData) => void;
    dragging?: CardData | null;
    icon: React.ReactNode;
    style?: React.CSSProperties;
    cardText?: string;
    showSort?: boolean;
}

function SortButtons() {
    const { order, setOrder } = useTasksContext();

    const alphaClass = order === "alphabetical" ? "m-1 text-cerulean-600 dark:text-aquamarine-400" : "m-1";
    const dueClass = order === "due" ? "m-1 text-cerulean-600 dark:text-aquamarine-400" : "m-1";
    const priorityClass = order === "priority" ? "m-1 text-cerulean-600 dark:text-aquamarine-400" : "m-1";
    const createdClass = order === "created" ? "m-1 text-cerulean-600 dark:text-aquamarine-400" : "m-1";

    return (<div className="flex ml-4">
        <FaSortAlphaDown title="Alphabetical" className={alphaClass} onClick={() => setOrder("alphabetical")} />
        <FaCalendarDay title="Due Date" className={dueClass} onClick={() => setOrder("due")} />
        <FaCalendarPlus title="Created" className={createdClass} onClick={() => setOrder("created")} />
        <FaSortAmountDown title="Priority" className={priorityClass} onClick={() => setOrder("priority")} />
    </div>);
}

function UtilityCard({ handleCardClick,
    handleCardDrop,
    dragging,
    icon,
    style,
    cardText,
    showSort }: UtilityCardProps) {

    const [, drop] = useDrop({
        accept: "GRID_ITEM",
        drop(item: unknown, monitor: DropTargetMonitor) {
            const cardItem = item as CardData;
            if (monitor.isOver() && handleCardDrop != undefined) {
                handleCardDrop(cardItem);
            }
        },
    });

    const canClick = handleCardClick !== undefined;
    let classes = "utility-card"
    if (canClick) {
        classes += " cursor-click";
    }
    if (dragging) {
        classes += " utility-card--highlighted";
    }

    return (<div id="utilityCard">
        <button ref={drop} onClick={handleCardClick} className={classes} style={style} >
            <Tooltip>
                <TooltipTrigger>
                    <div className="flex items-center justify-center text-center m-auto text-4xl"
                        title={cardText}
                    >
                        {icon}
                    </div>
                </TooltipTrigger>
                <TooltipContent className="text-black dark:text-white"><p>{cardText || "Utility card"}</p></TooltipContent>
            </Tooltip>
        </button>
        {showSort && <SortButtons />}
    </div>
    );
}
export default UtilityCard;
