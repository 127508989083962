import React from "react";
import { useNavigate } from "react-router-dom";
import { CreditCard, User } from "lucide-react";
import { MdExitToApp } from "react-icons/md";

import NotificationBell from "@/components/NotificationBell";
import { SearchControl } from "@/components/SearchControl";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuTrigger } from "@/components/ui/dropdownmenu";
import { PersonelleImage } from "@/components/Personelle";
import { useAppContext } from "@/contexts";

function UserInfoArea() {
    const { loggedInUser } = useAppContext();
    const [isSettingsOpen, setIsSettingsOpen] = React.useState(false);
    const navigate = useNavigate();

    if (!loggedInUser) {
        return null;
    }

    function handleLogoutClick() {
        window.location.replace("/auth/logout");
    }

    return (
        <div className="omnibar__user-area items-center">
            <NotificationBell />
            <DropdownMenu>
                <DropdownMenuTrigger>
                    <PersonelleImage
                        userData={loggedInUser}
                        onMouseDown={() => setIsSettingsOpen(!isSettingsOpen)}
                        className="personelle__tag--omnibar"
                    />
                </DropdownMenuTrigger>
                <DropdownMenuContent className="w-52 text-center bg-neutral-50 dark:bg-neutral-950">
                    <DropdownMenuLabel>My Account</DropdownMenuLabel>
                    <DropdownMenuItem className="hover:bg-neutral-100 hover:dark:bg-neutral-900" onClick={() => navigate('/app/settings')} >
                        <User className="mr-2 h-4 w-4" />
                        <span>Settings</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem className="hover:bg-neutral-100 hover:dark:bg-neutral-900" onClick={() => navigate('/app/upgrade')} >
                        <CreditCard className="mr-2 h-4 w-4" />
                        <span>Upgrade</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem className="hover:bg-neutral-100 hover:dark:bg-neutral-900" onClick={() => handleLogoutClick()} >
                        <MdExitToApp className="mr-2 h-4 w-4" />
                        <span>Logout</span>
                    </DropdownMenuItem>
                </DropdownMenuContent>
            </DropdownMenu>
        </div>
    );
}

function OmniBar() {
    const { loggedInUser, mobile } = useAppContext();
    const [metaKeyText, setMetaKeyText] = React.useState("Ctrl + ");
    const [metaKey, setMetaKey] = React.useState<"meta" | "ctrl">("ctrl");

    React.useEffect(() => {
        if (navigator.userAgent && navigator.userAgent.toLowerCase().includes("mac")) {
            setMetaKeyText("⌘");
            setMetaKey("meta");
        }
    }, []);

    if (!loggedInUser) {
        return null;
    }

    return (
        <div className="omnibar">
            <div className="text-white items-center ml-8 my-auto">
                {!mobile && <span>{metaKeyText}K to search</span>}
                <SearchControl metaKey={metaKey} />
            </div>
            <UserInfoArea />
        </div>
    );
}

export default OmniBar;
