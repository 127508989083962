import React from 'react';
import logo from "../assets/SUBSEQ-logo-long.png";
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppContext, useUsersContext } from '../contexts';
import { RiMailSendLine } from 'react-icons/ri';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import Steps from './Steps';

const lightClassesBase = "bg-neutral-100 m-4 rounded-lg overflow-hidden text-neutral-800";
const darkClassesBase = "bg-neutral-950 m-4 rounded-lg overflow-hidden";
const systemClassesBase = "image-container";

const OnboardingPage = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { refreshUser, setTourState, tourState, selectedTheme, setTheme } = useAppContext();
    const { inviteToOrg } = useUsersContext();

    const setStep = (step: number) => {
        navigate(`/app/onboarding?step=${step}`);
    }

    const startTour = () => {
        setTourState({ ...tourState, onboarding: true });
        refreshUser();
        navigate('/app/projects')
    }

    const [lightClasses, setLightClasses] = React.useState(lightClassesBase);
    const [darkClasses, setDarkClasses] = React.useState(darkClassesBase);
    const [systemClasses, setSystemClasses] = React.useState(systemClassesBase);
    const [inviteSent, setInviteSent] = React.useState(false);

    React.useEffect(() => {
        if (selectedTheme === 'light') {
            setLightClasses(lightClassesBase + " active-border");
            setDarkClasses(darkClassesBase);
            setSystemClasses(systemClassesBase);
        } else if (selectedTheme === 'dark') {
            setLightClasses(lightClassesBase);
            setDarkClasses(darkClassesBase + " active-border");
            setSystemClasses(systemClassesBase);
        } else {
            setLightClasses(lightClassesBase);
            setDarkClasses(darkClassesBase);
            setSystemClasses(systemClassesBase + " active-border");
        }
    }, [selectedTheme]);

    function handleInviteSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        const emailsString = formData.get('emails') as string;
        const emails = emailsString.split(',').map(e => e.trim());
        for (const email of emails) {
            inviteToOrg(email);
        }
        setInviteSent(true);
    }

    const steps = [
        (<div className="flex-col items-center">
            <h1>Choose your <b>theme</b></h1>
            <div className="flex mt-8">
                <div className={lightClasses} onClick={() => setTheme('light')}>
                    <h2>Light</h2>
                    <img src={logo} alt="SubSeq" className="invert h-12 bg-neutral-950 p-4" />
                </div>
                <div className={darkClasses} onClick={() => setTheme('dark')}>
                    <h2 className="text-neutral-100">Dark</h2>
                    <img src={logo} alt="SubSeq" className="h-12 bg-neutral-950 p-4" />
                </div>
                <div className={systemClasses} onClick={() => setTheme(null)}>
                    <h2>System</h2>
                    <img src={logo} alt="SubSeq" />
                </div>
            </div>
            <div className="mt-4">You can select your theme again at any time in the settings</div>
            <button className="btn mt-10" onClick={() => setStep(1)}>Continue</button>
        </div>),
        (<div className="flex-col items-center w-1/2">
            <h1>SubSeq is built for teams</h1>
            <div className="text-lg m-4">Invite your coworkers to join to collaborate. You can have up to 3 team members in the starter tier.</div>
            <div className="description__container">
                {!inviteSent ? <>
                    <p>Enter your coworkers email addresses (comma separated) and click invite</p>
                    <form onSubmit={handleInviteSubmit} className="flex mt-4">
                        <Input name="emails" type="email" placeholder="Email addresses" multiple className="input w-full mr-4 p-1" />
                        <Button type="submit" className="btn w-24 flex"><RiMailSendLine className="m-1" />Invite</Button>
                    </form></> : <>
                    <p>Invites sent!</p>
                </>}
            </div>
            <div className="mt-4">Invite more team members from the Team page</div>
            <button className="btn mt-10" onClick={() => setStep(2)}>Continue</button>
        </div>),
        (<div className="flex-col items-center">
            <h1>You're almost ready!</h1>
            <div className="text-lg mt-4">Please follow along with our guided tour</div>
            <button className="btn mt-10" onClick={startTour}>Get Started</button>
        </div>),
    ];
    const stepsNames = ["Choose Theme", "Invite Team", "Start Tour"];

    let stepNumber = parseInt(searchParams.get('step') || '0') || 0;
    if (stepNumber < 0 || stepNumber > steps.length) {
        stepNumber = 0;
    }

    return <div className="h-dvh w-dvw text-center flex flex-col items-center">
        <img src={logo} alt="SubSeq" className="sidebar__logo-img h-20 m-20" />
        {steps[stepNumber]}
        <div className="progress-dots">
            <Steps steps={stepsNames} currentStep={stepNumber} setStep={setStep} />
        </div>
    </div>;
}
export default OnboardingPage;
