import React, { useEffect, useState, useCallback } from "react";
import EasyCropper from 'react-easy-crop';
import {
    FaMagnifyingGlassMinus,
    FaMagnifyingGlassPlus,
    FaRotateLeft,
    FaRotateRight
} from "react-icons/fa6";
import axios from "axios";

import { cache, eventEmitter, useAppContext, useUsersContext } from "@/contexts";
import { Label } from "@/components/ui/label";

interface Point {
    x: number;
    y: number;
}

const defaultCrop: Point = { x: 0, y: 0 };
const defaultRotation = 0;
const defaultZoom = 1;
const defaultCroppedAreaPixels = null;

const minZoom = 1;
const maxRotation = 180;
const minRotation = -180;
const maxImageSize = 10 * 1024 * 1024;

function clamp(min: number, num: number, max: number): number {
    return num > max ? max : num < min ? min : num;
}

async function createImage(url: string): Promise<HTMLImageElement> {
    const image = new Image();
    return new Promise((resolve, reject) => {
        image.addEventListener('load', () => resolve(image));
        image.addEventListener('error', error => reject(error));
        image.src = url;
    });
}

function getRadianAngle(degreeValue: number): number {
    return (degreeValue * Math.PI) / 180;
}

interface ImageDims {
    width: number;
    height: number;
}

function rotateSize(width: number, height: number, rotation: number): ImageDims {
    const rotRad = getRadianAngle(rotation);

    return {
        width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
        height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height)
    };
}

interface CropDims {
    x: number;
    y: number;
    width: number;
    height: number;
}

interface Flip {
    horizontal: boolean;
    vertical: boolean;
}

interface CroppedImage {
    file: Blob;
    url: string;
}

async function getCroppedImg(
    imageSrc: string,
    pixelCrop: CropDims = { x: 0, y: 0, width: 0, height: 0 },
    rotation: number = 0,
    flip: Flip = { horizontal: false, vertical: false }
): Promise<CroppedImage> {
    const image: HTMLImageElement = await createImage(imageSrc);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
        throw null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0);

    return new Promise((resolve, reject) => {
        canvas.toBlob(file => {
            if (file !== null) {
                resolve({ file, url: URL.createObjectURL(file) });
            } else {
                reject(null);
            }
        },
            'image/png',
            0.4);
    });
}

interface ImageCropContextProps {
    isEditing: boolean;
    setIsEditing: (isEditing: boolean) => void;
    image: null | string,
    setImage: (image: string) => void,
    zoom: number,
    setZoom: (zoom: number) => void,
    rotation: number,
    setRotation: (rotation: number) => void,
    crop: Point,
    setCrop: (crop: Point) => void,
    croppedAreaPixels: null | CropDims,
    setCroppedAreaPixels: (croppedAreaPixels: null | CropDims) => void,
    onCropComplete: (croppedArea: CropDims, croppedAreaPixels: CropDims) => void,
    getProcessedImage: () => Promise<File | null>,
    handleZoomIn: () => void,
    handleZoomOut: () => void,
    handleRotateCcw: () => void,
    handleRotateCw: () => void,
    maxZoom: number,
    minZoom: number,
    zoomStep: number,
    maxRotation: number,
    minRotation: number,
    rotationStep: number,
    resetStates: () => void
}

interface SliderProps {
    value: number;
    valueMin: number;
    valueMax: number;
    setValue: (value: number) => void;
    valueStepUp: () => void;
    valueStepDown: () => void;
    valueStep: number;
    stepDownIcon: React.ReactNode;
    stepUpIcon: React.ReactNode;
}

function Slider({ value, valueMin, valueMax, setValue, valueStepDown, valueStepUp, valueStep, stepDownIcon, stepUpIcon }: SliderProps) {
    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        setValue(Number(e.target.value));
    }
    return (
        <div className="flex items-center justify-center gap-2">
            <button className="p-1" onClick={valueStepDown}>
                {stepDownIcon}
            </button>
            <input
                type="range"
                min={valueMin}
                max={valueMax}
                step={valueStep}
                value={value}
                onChange={handleChange}
            />
            <button className="p-1" onClick={valueStepUp}>
                {stepUpIcon}
            </button>
        </div>
    );
}

function Cropper({ context }: { context: ImageCropContextProps }) {
    const { image,
        zoom,
        setZoom,
        handleZoomOut,
        handleZoomIn,
        maxZoom,
        zoomStep,
        rotation,
        setRotation,
        handleRotateCw,
        handleRotateCcw,
        rotationStep,
        crop,
        setCrop,
        onCropComplete } = context;

    return (
        <div>
            <div className="image-upload__cropper">
                <EasyCropper
                    image={image || undefined}
                    crop={crop}
                    zoom={zoom}
                    rotation={rotation}
                    cropShape="round"
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    onRotationChange={setRotation}
                    showGrid={false}
                    cropSize={{ width: 185, height: 185 }}
                />
            </div>
            <Slider
                value={zoom}
                valueMin={minZoom}
                valueMax={maxZoom}
                setValue={setZoom}
                valueStepDown={handleZoomOut}
                valueStepUp={handleZoomIn}
                valueStep={zoomStep}
                stepDownIcon={<FaMagnifyingGlassMinus />}
                stepUpIcon={<FaMagnifyingGlassPlus />}
            />
            <Slider
                value={rotation}
                valueMin={minRotation}
                valueMax={maxRotation}
                setValue={setRotation}
                valueStepDown={handleRotateCcw}
                valueStepUp={handleRotateCw}
                valueStep={rotationStep}
                stepDownIcon={<FaRotateLeft />}
                stepUpIcon={<FaRotateRight />}
            />
        </div>
    );
}

interface ImageContextualDisplayProps {
    userId: string;
    jobTitle: string;
    maxZoom: number;
    zoomStep: number;
    rotationStep: number;
}

function ImageContextualDisplay({ userId, jobTitle, maxZoom, zoomStep, rotationStep }: ImageContextualDisplayProps) {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [image, setImage] = useState<null | string>(null);
    const [crop, setCrop] = useState(defaultCrop);
    const [rotation, setRotation] = useState(defaultRotation);
    const [zoom, setZoom] = useState(defaultZoom);
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<null | CropDims>(defaultCroppedAreaPixels);

    const onCropComplete = useCallback((_croppedArea: CropDims, croppedAreaPixels: CropDims) => {
        setCroppedAreaPixels(croppedAreaPixels);
    }, []);

    function handleZoomIn() {
        setZoom(clamp(minZoom, zoom + zoomStep, maxZoom));
    }

    function handleZoomOut() {
        setZoom(clamp(minZoom, zoom - zoomStep, maxZoom));
    }

    function handleRotateCw() {
        setRotation(clamp(minRotation, rotation + rotationStep, maxRotation));
    }

    function handleRotateCcw() {
        setRotation(clamp(minRotation, rotation - rotationStep, maxRotation));
    }

    async function getProcessedImage(): Promise<File | null> {
        if (image && croppedAreaPixels) {
            const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation);
            const imageFile = new File([croppedImage.file], `img-${Date.now()}.png`, {
                type: 'image/png'
            });
            return imageFile;
        }
        return null;
    }

    function resetStates() {
        setIsEditing(false);
        setCrop(defaultCrop);
        setRotation(defaultRotation);
        setZoom(defaultZoom);
        setCroppedAreaPixels(defaultCroppedAreaPixels);
    }

    const cropContextProps: ImageCropContextProps = {
        isEditing,
        setIsEditing,
        image,
        setImage,
        zoom,
        setZoom,
        rotation,
        setRotation,
        crop,
        setCrop,
        croppedAreaPixels,
        setCroppedAreaPixels,
        onCropComplete,
        getProcessedImage,
        handleZoomIn,
        handleZoomOut,
        handleRotateCcw,
        handleRotateCw,
        maxZoom,
        minZoom,
        zoomStep,
        maxRotation,
        minRotation,
        rotationStep,
        resetStates
    };
    return (
        <ImageUpload userId={userId} jobTitle={jobTitle} context={cropContextProps} />
    );
}

interface ImageUploadProps {
    userId: string;
    jobTitle: string;
    context: ImageCropContextProps;
}

function genKey(): string {
    return Math.random().toString(36);
}

function ImageUpload({ userId, jobTitle, context }: ImageUploadProps) {
    const { users } = useUsersContext();
    const { image, setImage, isEditing, setIsEditing } = context;
    const [fileKey, setFileKey] = useState(genKey());
    const [imageId, setImageId] = useState<string>("");
    const { loggedInUser } = useAppContext();

    useEffect(() => {
        cache.getPortrait(userId).then((portrait: string) => {
            setImageId(portrait);
        });
    }, [userId]);

    useEffect(() => {
        if (!isEditing) {
            setFileKey(genKey());
        }
    }, [isEditing]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const url = URL.createObjectURL(event.target.files[0])
            setImage(url);
            setIsEditing(true);
        }
    };

    const handleSubmit = async () => {
        if (image === null) {
            return;
        }

        const file = await context.getProcessedImage();
        if (!file) {
            eventEmitter.emit("ERROR", "Failed to process image");
            return;
        }

        if (file.size > maxImageSize) {
            eventEmitter.emit("ERROR", "File size too large");
            return;
        }

        const formData = new FormData();
        formData.append("file", file);
        try {
            await axios.put(`/portrait/${userId}`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
        } catch (error) {
            eventEmitter.emit("ERROR", `Upload failed: ${error}`);
            setIsEditing(false);
            return;
        }
        const url = URL.createObjectURL(file);
        cache.setPortrait(userId, url);
        const user = users.find((user) => user.id === userId);
        if (user) {
            user.imageId = url;
        }
        setImageId(url);
        setIsEditing(false);
    };

    return (
        <div className="mt-4 mb-4 flex flex-col items-center justify-between rounded-lg border p-4">
            <div className="image-upload__portrait items-center justify-between ">
                {!isEditing && <img src={imageId} className="image-upload__img" />}
                {isEditing && <Cropper context={context} />}
                <div className="flex flex-col">
                    <h5>Job Title</h5>
                    <Label className="card-text m-4 text-2xl">{jobTitle}</Label>
                </div>
            </div>
            {userId === loggedInUser?.id &&
                <form id="SetProfile" className="image-upload__form">
                    <input className="hidden" type="file" id="profile-img-upload" key={fileKey} onChange={handleFileChange} />
                    <label className="image-upload__button" htmlFor="profile-img-upload">Change Portrait</label>
                    {isEditing && <label className="image-upload__button" onClick={handleSubmit}>Save Image</label>}
                </form>}
        </div>
    );
}

export default ImageContextualDisplay;
