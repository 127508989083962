import { FaDiagramProject } from "react-icons/fa6";

import { Project } from "@/contexts/Project";
import { Select, SelectTrigger, SelectGroup, SelectLabel, SelectContent, SelectItem } from "@/components/ui/select";
import { useProjectsContext } from "@/contexts";

interface SideBarTextProps {
    icon: React.ReactNode;
    text: string;
}

const SideBarText = ({ icon, text }: SideBarTextProps) => (
    <div id="activeProjectSidebarIcon" className="cursor-pointer panel max-w-[90%]">
        <div className="flex items-center">
            <div className="flex-grow" />
            {icon}
            <span className="mt-2 mb-2 text-sm truncate basis-[85%]">{text}</span>
        </div>
    </div>
);

interface ProjectSelectProps {
    activeProject: Project | null;
}

function ProjectSelect({ activeProject }: ProjectSelectProps) {
    const { projects, orgProjects, setActiveProject } = useProjectsContext();

    const onClickProject = (projectId: string) => {
        console.log("Setting active project", projectId);
        const project = projects.find((project) => project.id === projectId);
        if (project) {
            setActiveProject(project);
            return;
        }
        const orgProject = orgProjects.find((project) => project.id === projectId);
        if (orgProject) {
            setActiveProject(orgProject);
        }
    }
    const activeProjectString = activeProject?.name || "";

    return <Select onValueChange={onClickProject}>
        <SelectTrigger className="panel border-none">
            <SideBarText icon={<FaDiagramProject className="m-1 basis-[15%]" />} text={activeProjectString} />
        </SelectTrigger>
        <SelectContent position="popper" side="right" className="panel">
            <SelectGroup>
                <SelectLabel>Personal Projects</SelectLabel>
                {projects.map((project) => (
                    <SelectItem className="hover:bg-neutral-300 dark:hover:bg-neutral-700" key={project.id} value={project.id}>
                        {project.name}
                    </SelectItem>
                ))}
            </SelectGroup>
            <SelectGroup>
                <SelectLabel>Team Projects</SelectLabel>
                {orgProjects.map((project) => (
                    <SelectItem className="hover:bg-neutral-300 dark:hover:bg-neutral-700" key={project.id} value={project.id}>
                        {project.name}
                    </SelectItem>
                ))}
            </SelectGroup>
        </SelectContent>
    </Select>;
}

export default ProjectSelect;
