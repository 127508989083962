import store from 'store2';
import { ChevronLeft } from 'lucide-react';
import { FaMagnifyingGlass } from 'react-icons/fa6';
import { FaPlusSquare } from 'react-icons/fa';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'sonner';
import { useCallback, useEffect, useState } from 'react';

import Checkbox from '@/components/ui/checkbox';
import LedDisplay from '@/components/LED';
import Spinner from '@/components/Spinner';
import Steps from '@/components/Steps';
import { Accordion, AccordionContent, AccordionItem } from '@/components/ui/accordion';
import { Button } from '@/components/ui/button';
import { ImportStateInfo, ImportUpdate, Project, ProjectImportState } from '@/contexts/Project';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { PersonelleImage } from '@/components/Personelle';
import { ScrollArea } from '@/components/ui/scrollarea';
import { Select, SelectGroup, SelectItem, SelectContent, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table';
import { Toaster } from '@/components/ui/toaster';
import { UserData } from '@/contexts/User';
import { apiFetch, eventEmitter, isDevelopment, useProjectsContext, useTasksContext, useUsersContext } from '@/contexts';

async function loginWithGithubCode({ code, state }: { code: string, state: string | null }): Promise<boolean> {
    const response = await fetch('/github/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ code, state })
    });
    if (!response.ok) {
        const error = await response.text();
        console.error(error);
    }
    return response.ok;
}

// Store the code and state from the Github login redirect so we can use it in an auth context
export function GithubImportLogin() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const code = searchParams.get('code');
    const state = searchParams.get('state');
    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');
    const errorUri = searchParams.get('error_uri');

    if (error) {
        store.set('githubError', { error, errorDescription, errorUri });
    }

    if (code) {
        store.set('githubCode', { code, state });
    }

    useEffect(() => {
        navigate('/app/import/github');
    }, [navigate]);
    return null;
}

interface GithubRepository {
    id: number;
    fullName: string;
    private: boolean;
    syncEnabled: boolean;
}

interface GithubConnectedAccount {
    user: UserData;
    installationId: number;
    createdAt: string;
    accountType: string;
    accountLogin: string;
    accountAvatarUrl: string;
    accountHtmlUrl: string;
    repositories: GithubRepository[];
}

async function testData(): Promise<GithubConnectedAccount[]> {
    return [
        {
            user: {
                id: '1',
                created: '2024-08-01T00:00:00Z',
                username: 'filip',
                email: 'filip@subseq.io',
                jobTitle: 'Manager',
            },
            installationId: 1,
            createdAt: '2024-08-30T00:00:00Z',
            accountType: 'organization',
            accountLogin: 'subseq-io',
            accountAvatarUrl: 'https://avatars.githubusercontent.com/u/1?v=4',
            accountHtmlUrl: 'https://github.com/subseq-io/zini',
            repositories: [
                {
                    id: 1,
                    syncEnabled: false,
                    private: true,
                    fullName: "subseq-io/zini"
                },
                {
                    id: 2,
                    syncEnabled: true,
                    private: false,
                    fullName: "subseq-io/subseq_util"
                },
                {
                    id: 3,
                    syncEnabled: false,
                    private: true,
                    fullName: "subseq-io/zini-frontend"
                },
                {
                    id: 4,
                    syncEnabled: true,
                    private: true,
                    fullName: "subseq-io/subseq_scheduling"
                },
                {
                    id: 5,
                    syncEnabled: false,
                    private: true,
                    fullName: "subseq-io/orchard"
                },
                {
                    id: 6,
                    syncEnabled: true,
                    private: true,
                    fullName: "subseq-io/sage"
                },
                {
                    id: 7,
                    syncEnabled: true,
                    private: true,
                    fullName: "subseq-io/arborist"
                }
            ]
        }
    ];
}

async function getGithubConnectedAccounts(): Promise<GithubConnectedAccount[]> {
    if (isDevelopment) {
        return testData();
    }

    return await apiFetch('/github/accounts', { method: 'GET' });
}

interface ImportInitialState {
    projectImportStates: ProjectImportState[];
}

interface SelectedRepository {
    repoId: number;
    installationId: number;
}

interface GithubImportConfigurationProps {
    steps: string[];
    setImportState: (state: ImportInitialState) => void;
}

function GithubImportConfiguration({ steps, setImportState }: GithubImportConfigurationProps) {
    const { orgProjects } = useProjectsContext();
    const step = 0;
    const [githubConnectedAccounts, setGithubConnectedAccounts] = useState<GithubConnectedAccount[]>([]);
    const [selectedRepositories, setSelectedRepositories] = useState<SelectedRepository[]>([]);

    const [selectedProject, setSelectedProject] = useState<null | Project>(null);
    const [newProjectTitle, setNewProjectTitle] = useState<string>("");
    const [accordionValue, setAccordionValue] = useState<string>("");


    useEffect(() => {
        getGithubConnectedAccounts().then((accounts) => {
            setGithubConnectedAccounts(accounts);
        });

        const handleGithubConnectedAccount = ({ account }: { account: GithubConnectedAccount }) => {
            toast.success(`Account ${account.accountLogin} connected`);
            setGithubConnectedAccounts((accounts) => {
                return [...accounts, account];
            });
        };

        eventEmitter.on('CONNECTED-ACCOUNT', handleGithubConnectedAccount);
        return () => {
            eventEmitter.off('CONNECTED-ACCOUNT', handleGithubConnectedAccount);
        }
    }, []);

    const repos = [];
    for (const account of githubConnectedAccounts) {
        for (const repo of account.repositories) {
            const storedRepo = { ...repo, installationId: account.installationId };
            repos.push(storedRepo);
        }
    }

    const handleSelectProject = (value: string) => {
        if (value === 'create') {
            setAccordionValue("item-1");
            setSelectedProject(null);
        } else {
            setAccordionValue("");
            setSelectedProject(orgProjects.find((proj) => proj.id === value) || null);
        }
    };

    const handleStartImport = () => {
        if (selectedRepositories.length === 0 || (selectedProject === null && newProjectTitle === "")) {
            return;
        }

        // Start the import
        let connectedProject;
        if (selectedProject) {
            connectedProject = { existingProject: selectedProject.id };
        } else {
            connectedProject = { newProject: newProjectTitle };
        }

        apiFetch<ImportInitialState>('/github/import', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                project: connectedProject,
                repos: selectedRepositories,
            })
        })
            .then(setImportState)
            .catch((error) => {
                error.text().then((error: string) => {
                    eventEmitter.emit('ERROR', error);
                });
            });
    }

    const isSelected = (repo: GithubRepository) => selectedRepositories.find((selectedRepo) => repo.id === selectedRepo.repoId) !== undefined;

    return (<div className="description__container p-2 mt-10 mb-10 w-[600px]">
        <Link to="../.." className='flex m-4 no-underline'>
            <Button><ChevronLeft /> Exit</Button>
        </Link>
        <h1 className="m-3">Import projects from Github</h1>
        <Steps steps={steps} currentStep={step} />
        <div className="dark:bg-neutral-700 rounded-lg border border-neutral-200 mt-4">
            <div className="flex items-center p-4">
                <span className="ml-2">Connected accounts</span>
                <div className="flex-grow" />
                <Link className="mr-10" to="https://github.com/apps/subseq-github-sync/installations/select_target" target="_blank">
                    <FaPlusSquare className="mt-1 links_dot" />
                </Link>
            </div>
            {githubConnectedAccounts.map((account) => (
                <div key={account.accountLogin} className="flex items-center justify-between p-4 border-t">
                    <div className="flex items-center">
                        <img src={account.accountAvatarUrl} alt="avatar" className="w-10 h-10 rounded-full" />
                        <div className="ml-8">
                            <p>{account.accountType}</p>
                            <p>{account.accountLogin}</p>
                        </div>
                    </div>
                    <div className="flex">
                        <LedDisplay status="green" />Connected
                    </div>
                </div>
            ))}
        </div>
        {repos.length === 0 ? <p className="m-3">
            Connect an account to get started.
        </p> : <>
            <h4 className="m-4">Select repositories to import</h4>
            <ScrollArea className="h-48 border rounded-md">
                <Table>
                    <TableBody>
                        {repos.map((repo) => (
                            <TableRow className='text-left hover:bg-neutral-300 dark:hover:bg-neutral-800' key={repo.id}>
                                <TableCell className='text-center'>
                                    <Checkbox checked={isSelected(repo)}
                                        onCheckedChange={(checked) => checked ? setSelectedRepositories((sel) => [...sel, { repoId: repo.id, installationId: repo.installationId }]) :
                                            setSelectedRepositories((sel) => sel.filter((value) => value.repoId !== repo.id))} />
                                </TableCell>
                                <TableCell>{repo.fullName}</TableCell>
                                <TableCell>{repo.private ? 'Private' : 'Public'}</TableCell>
                                <TableCell>{repo.syncEnabled ? "Synced" : ""}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </ScrollArea>
            <h4 className="m-4">Select project for import</h4>
            <div className="p-4 border rounded-md">
                <Select onValueChange={handleSelectProject}>
                    <SelectTrigger className='m-auto mt-4 mb-4 w-[180px]'>
                        <SelectValue placeholder="Select a project" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectItem className="select__hover" value="create">Create new project</SelectItem>
                        </SelectGroup>
                        <SelectGroup>
                            {orgProjects.map((proj) => (
                                <SelectItem className="select__hover" key={proj.id} value={proj.id}>{proj.name}</SelectItem>
                            ))}
                        </SelectGroup>
                    </SelectContent>
                </Select>
                <Accordion type='single' collapsible value={accordionValue} onValueChange={setAccordionValue} >
                    <AccordionItem value="item-1">
                        <AccordionContent className="p-4 border flex items-center justify-center">
                            <Label className="m-4">Project name</Label>
                            <Input className="w-[180px]" value={newProjectTitle} onChange={(e) => setNewProjectTitle(e.currentTarget.value)} />
                        </AccordionContent>
                    </AccordionItem>
                </Accordion>
            </div>
            <div className="flex m-4">
                <div className="flex-grow" />
                <Button className="m-4 btn" disabled={selectedRepositories.length === 0 || (selectedProject === null && newProjectTitle === "")} onClick={handleStartImport}>Start Import</Button>
            </div>
        </>}
    </div>);
}

function activeImportTest(): ImportInitialState {
    return {
        projectImportStates: [
            {
                id: '1', projectId: '1', projectTracker: {
                    id: '10',
                    projectId: '1',
                    tracker: 'github',
                    repo: 'subseq-io/zini',
                    created: '2024-04-04',
                    repoUrl: null,
                    isDefault: false
                }, importState: { inProgress: "issues" }, importError: null
            },
            {
                id: '2', projectId: '1', projectTracker: {
                    id: '11',
                    projectId: '1',
                    tracker: 'github',
                    repo: 'subseq-io/sage',
                    created: '2024-04-04',
                    repoUrl: null,
                    isDefault: false
                },
                importState: { inProgress: "comments" }, importError: null
            },
            {
                id: '3',
                projectId: '1',
                projectTracker: {
                    id: '12',
                    projectId: '1',
                    tracker: 'github',
                    repo: 'subseq-io/zini-frontend',
                    created: '2024-04-04',
                    repoUrl: null,
                    isDefault: false
                }, importState: "done", importError: null
            },
        ],
    };
}

async function getActiveImport(): Promise<ImportInitialState | null> {
    if (isDevelopment) {
        return activeImportTest();
    }

    try {
        return await apiFetch<ImportInitialState>('/github/import', { method: 'GET' });
    } catch {
        return null;
    }
}

interface GithubImportingProps {
    steps: string[];
    importState: ImportInitialState;
    stateInfos: Map<string, ImportStateInfo>;
    setStep: (step: number) => void;
}

function displayInfo(info: ImportStateInfo | undefined) {
    if (typeof info === "object") {
        if ("issues" in info) {
            return `(${info.issues.issues} issues)`;
        } else if ("comments" in info) {
            return `(${info.comments.total} issues)`;
        } else if ("portraits" in info) {
            return `(${info.portraits.total} users)`;
        }
    }
    return null;
}

function GithubImporting({ steps, importState, stateInfos, setStep }: GithubImportingProps) {
    const step = 1;
    let continueActive = true;

    return (<div className="description__container p-2 mt-10 mb-10 w-[600px]">
        <Link to="../.." className='flex m-4 no-underline'>
            <Button><ChevronLeft /> Exit</Button>
        </Link>
        <h1 className="m-3">Your projects are importing from Github</h1>
        <Steps steps={steps} currentStep={step} />
        <div className="dark:bg-neutral-700 rounded-lg border border-neutral-200 mt-4">
            {importState.projectImportStates.map((state, index) => {
                const error = state.importError;
                let color: "red" | "orange" | "green";
                let blink = false;
                if (error) {
                    color = "red";
                } else if (state.importState === "done") {
                    color = "green";
                } else {
                    color = "orange";
                    blink = true;
                }

                const info = stateInfos.get(state.projectTracker.id);
                const infoDisplay = info ? displayInfo(info) : '';

                let stateString;
                if (typeof (state.importState) === 'object' && 'inProgress' in state.importState) {
                    if (infoDisplay) {
                        stateString = `Importing ${state.importState.inProgress} ${infoDisplay}`;
                    } else {
                        stateString = `Importing ${state.importState.inProgress}`;
                    }
                    continueActive = false;
                } else {
                    if (state.importState === "done") {
                        stateString = "Imported";
                    } else {
                        stateString = state.importState.toString();
                        continueActive = false;
                    }
                }

                const topDivClass = index > 0 ? "flex items-center justify-between p-4 border-t" : "flex items-center justify-between p-4";
                return <div key={state.id} className={topDivClass}>
                    <div className="flex items-center">
                        <div className="ml-8">
                            <p>{state.projectTracker.repo}</p>
                        </div>
                    </div>
                    <div className="flex">
                        {stateString}<LedDisplay status={color} blink={blink} />
                    </div>
                </div>;
            })}
        </div>
        <div className="flex items-center justify-center m-4">
            <div className="flex-grow" />
            <Button className="btn" disabled={!continueActive} onClick={() => setStep(2)}>Continue</Button>
        </div>
    </div>);
}

async function getImportedUsers(): Promise<UserData[]> {
    if (isDevelopment) {
        return [
            { id: '1', created: '2024-08-08', username: 'filip', email: 'filip@github.com', jobTitle: 'Manager' },
            { id: '2', created: '2024-08-08', username: 'bill', email: 'filip@github.com', jobTitle: 'Manager' },
            { id: '3', created: '2024-08-08', username: 'william', email: 'filip@github.com', jobTitle: 'Manager' },
            { id: '4', created: '2024-08-08', username: 'salad', email: 'filip@github.com', jobTitle: 'Manager' },
        ];
    }
    return await apiFetch('/github/import/users', { method: 'GET' });
}

async function linkGithubUsers(request: { userToLink: string, importedUsers: string[] }) {
    const response = await fetch('/github/import/users', {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(request),
    });
    if (!response.ok) {
        const error = await response.text();
        throw error;
    }
}

function GithubAccountLinking({ steps }: { steps: string[] }) {
    const step = 2;
    const { users } = useUsersContext();
    const { clearCache } = useTasksContext();
    const [importedUsers, setImportedUsers] = useState<UserData[]>([]);

    const [selectedUser, setSelectedUser] = useState<string | null>(null);
    const [selectedImportedUsers, setImportedSelected] = useState<string[]>([]);

    const [alreadyLinkedUsers, setAlreadyLinkedUsers] = useState<string[]>([]);
    const [alreadyLinkedImportedUsers, setAlreadyLinkedImportedUsers] = useState<string[]>([]);
    const activeUsers = users.filter((user) => !alreadyLinkedUsers.includes(user.id));

    useEffect(() => {
        if (isDevelopment) {
            setAlreadyLinkedUsers(['1', '2']);
            setAlreadyLinkedImportedUsers(['1', '2']);
        }
    }, []);

    const linkUsers = useCallback(() => {
        if (selectedImportedUsers.length === 0 || selectedUser === null) {
            return;
        }
        linkGithubUsers({ userToLink: selectedUser, importedUsers: selectedImportedUsers })
            .then(() => {
                toast.success('Users linked successfully');
                setSelectedUser(null);
                setImportedSelected([]);
                setAlreadyLinkedImportedUsers((linked) => [...linked, ...selectedImportedUsers]);
                setAlreadyLinkedUsers((linked) => [...linked, selectedUser]);
                clearCache();
            })
            .catch((error) => {
                eventEmitter.emit('ERROR', error);
            });
    }, [selectedImportedUsers, selectedUser, clearCache]);

    const setSelected = useCallback((userId: string) => {
        setImportedSelected((selected) => {
            if (selected.includes(userId)) {
                return selected.filter((id) => id !== userId);
            } else {
                return [...selected, userId];
            }
        });
    }, []);

    useEffect(() => {
        getImportedUsers().then(setImportedUsers);
    }, []);

    const [filter, setFilter] = useState("");
    const filterList = useCallback((users: UserData[]) => {
        const filteredUsers = users.filter((user) => !alreadyLinkedImportedUsers.includes(user.id));

        if (filter === "") {
            return filteredUsers;
        }

        return users.filter((user) => user.username.toLowerCase().includes(filter.toLowerCase()));
    }, [filter, alreadyLinkedImportedUsers]);
    const filteredUsers = filterList(importedUsers);

    const listItems = filteredUsers.map((userData) => (
        <li key={userData.id} onMouseDown={() => setSelected(userData.id)} className="flex items-center">
            <PersonelleImage userData={userData} className="p-1 rounded-full" />
            <span className="personelle__text">{userData.username}</span>
            <Checkbox checked={selectedImportedUsers.indexOf(userData.id) > -1} className="ml-2 mr-1" />
        </li>
    ));

    return (<div className="description__container p-2 mt-10 mb-10 w-[600px]">
        <Link to="../.." className='flex m-4 no-underline'>
            <Button><ChevronLeft /> Exit</Button>
        </Link>
        <h1 className="m-3">Connect Github accounts for your team</h1>
        <Steps steps={steps} currentStep={step} />
        <div className="grid grid-cols-2 p-2 border rounded-lg">
            <div className="p-2">
                <Select onValueChange={setSelectedUser}>
                    <SelectTrigger className="m-2">
                        <SelectValue placeholder="Select a team member to link"></SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            {activeUsers.map((user) => (
                                <SelectItem value={user.id}>
                                    <div className="flex items-center">
                                        <PersonelleImage userData={user} className="p-1 rounded-full" />
                                        <div className="ml-4 personelle__text">
                                            {user.username}
                                        </div>
                                    </div>
                                </SelectItem>
                            ))}
                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>
            <div className="p-2">
                <FaMagnifyingGlass className="absolute translate-y-5 translate-x-64" />
                <Input className="m-2" onChange={(e) => setFilter(e.target.value)} value={filter} />
                <ul>
                    {listItems}
                </ul>
            </div>
            <Button disabled={selectedUser === null || selectedImportedUsers.length === 0} className="btn col-span-2 m-auto mt-4 mb-4 w-1/3" onClick={linkUsers}>Link Accounts</Button>
        </div>
        <div className="flex m-4">
            <div className="flex-grow" />
            <Link to="/app/projects"><Button className="btn">Done</Button></Link>
        </div>
    </div>);
}

function GithubImportPage() {
    const steps = ["Configure", "Import", "Link"];
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { clearProjectCache } = useProjectsContext();
    let step = parseInt(searchParams.get('step') || '0') || 0;
    if (step < 0 || step >= steps.length) {
        step = 0;
    }
    const [importState, setImportState_] = useState<ImportInitialState | null>(null);
    const [stateInfos, setStateInfos] = useState(new Map<string, ImportStateInfo>());

    const setStep = useCallback((step: number) => {
        navigate(`/app/import/github?step=${step}`);
    }, [navigate]);

    const setImportState = useCallback((state: ImportInitialState) => {
        setImportState_(state);
        if (state) {
            setStep(1);
        }
        clearProjectCache();
    }, [setStep, clearProjectCache]);

    useEffect(() => {
        const { code, state } = store.get('githubCode') || {};
        if (code) {
            // Associate the github account with the user id
            loginWithGithubCode({ code, state }).then((ok) => {
                if (ok) {
                    store.remove('githubCode');
                }
            });
        }
    }, []);

    useEffect(() => {
        getActiveImport().then(setImportState_);
        if (isDevelopment) {
            const newMap = new Map<string, ImportStateInfo>();
            newMap.set('10', { issues: { issues: 10 } });
            newMap.set('11', { comments: { total: 20 } });
            newMap.set('12', { portraits: { total: 30 } });
            setStateInfos(newMap);
        }
    }, []);

    useEffect(() => {
        eventEmitter.on('ERROR', toast.error)
        return () => {
            eventEmitter.off('ERROR', toast.error)
        }
    }, [])

    useEffect(() => {
        if (importState) {
            const handleImportUpdates = ({ update: stateUpdate }: { update: ImportUpdate }) => {
                console.log('IMPORT-UPDATE', stateUpdate);
                setStateInfos((infos) => {
                    const newInfos = new Map(infos);
                    newInfos.set(stateUpdate.projectTrackerId, stateUpdate.info);
                    return newInfos;
                });
                setImportState_((state) => {
                    if (state) {
                        return {
                            ...state, projectImportStates: state.projectImportStates.map((proj) => {
                                if (proj.projectTracker.id === stateUpdate.projectTrackerId) {
                                    return {
                                        ...proj,
                                        importState: stateUpdate.state,
                                        importError: stateUpdate.error,
                                    };
                                } else {
                                    return proj;
                                }
                            })
                        };
                    } else {
                        return null;
                    }
                });
            };
            eventEmitter.on('IMPORT-UPDATE', handleImportUpdates);
            return () => {
                eventEmitter.off('IMPORT-UPDATE', handleImportUpdates);
            }
        }
    }, [importState, setStep]);

    let displayNode = null;
    if (step === 0) {
        displayNode = <GithubImportConfiguration steps={steps} setImportState={setImportState} />;
    } else if (step === 1 && importState) {
        displayNode = <GithubImporting steps={steps} importState={importState} stateInfos={stateInfos} setStep={setStep} />;
    } else if (step === 2 && importState) {
        displayNode = <GithubAccountLinking steps={steps} />;
    } else {
        displayNode = <Spinner />;
    }

    return <>
        {displayNode}
        <Toaster />
    </>;
}
export default GithubImportPage;
