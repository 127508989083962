import { FaCog } from "react-icons/fa";
import { Moon, Sun, Computer } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import AppPage from "@/components/AppPage";
import DetailCard from "@/components/cards/DetailCard";
import { Button } from "@/components/ui/button";
import { apiFetch, apiFetchNoContent, useAppContext } from "@/contexts";
import { Label } from "@/components/ui/label";
import { UserData, isOrgAdmin, orgRoleString } from "@/contexts/User";
import ImageContextualDisplay from "@/components/cards/user/ImageUpload";
import { ScrollArea } from "@/components/ui/scrollarea";
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Switch } from "@/components/ui/switch";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogTrigger, DialogTitle } from "@/components/ui/dialog";
import { toast } from "sonner";

const SettingsSchema = z.object({
    email: z.object({
        notifications: z.boolean(),
        frequency: z.string(),
        channel_tasks: z.boolean().optional(),
    }),
    notifications: z.object({
        notifications: z.boolean(),
        channel_tasks: z.boolean().optional(),
    }),
    personal: z.object({
        job_title: z.string(),
        family_name: z.string(),
        given_name: z.string(),
        timezone: z.string().optional(),
        locale: z.string().optional(),
    }),
});

interface SubscriptionInfo {
    plan: string;
    status: string;
    startDate: string;
    billingDate: string;
    trialEnd?: string;
}

async function getSubscriptionPlan(): Promise<SubscriptionInfo> {
    return await apiFetch<SubscriptionInfo>('/org/subscription',
        { method: "GET", headers: { "Content-Type": "application/json" } }
    );
}

async function deactivateSubscription(): Promise<void> {
    return await apiFetchNoContent('/org/subscription',
        { method: "DELETE", headers: { "Content-Type": "application/json" } }
    );
}

const UserSettings = () => {
    const { narrow, selectedTheme, loggedInUser, setLoggedInUser, setTheme } = useAppContext();
    const [subscriptionPlan, setSubscriptionPlan] = useState<SubscriptionInfo | null>(null);

    const tristateToggle = () => {
        if (selectedTheme === "light") {
            setTheme("dark");
        } else if (selectedTheme === "dark") {
            setTheme(null);
        } else {
            setTheme("light");
        }
    }

    const themeIcon = () => {
        if (selectedTheme === "light") {
            return <Sun className="h-[1.2rem] w-[1.2rem] rotate-0 transition-all dark:-rotate-90" />;
        } else if (selectedTheme === "dark") {
            return <Moon className="h-[1.2rem] w-[1.2rem] rotate-90 transition-all dark:rotate-0" />;
        } else {
            return <Computer className="h-[1.2rem] w-[1.2rem] scale-100 transition-all" />;
        }
    }


    const form = useForm<z.infer<typeof SettingsSchema>>({
        resolver: zodResolver(SettingsSchema),
        defaultValues: useMemo(() => {
            const settings = loggedInUser?.settings;
            const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
            return {
                email: {
                    notifications: settings?.email.notifications ?? true,
                    frequency: settings?.email.frequency ?? "instant",
                    channel_tasks: settings?.email.channels.includes("all") ?? true,
                },
                notifications: {
                    notifications: settings?.notifications.notifications ?? true,
                    channel_tasks: settings?.notifications.channels.includes("all") ?? true,
                },
                personal: {
                    job_title: loggedInUser?.jobTitle,
                    family_name: loggedInUser?.familyName,
                    given_name: loggedInUser?.givenName,
                    timezone: settings?.personal.timezone ?? resolvedOptions.timeZone,
                    locale: settings?.personal.locale ?? resolvedOptions.locale,
                },
            };
        }, [loggedInUser]),
    });

    useEffect(() => {
        const settings = loggedInUser?.settings;
        const resolvedOptions = Intl.DateTimeFormat().resolvedOptions();
        form.reset({
            email: {
                notifications: settings?.email.notifications ?? true,
                frequency: settings?.email.frequency ?? "instant",
                channel_tasks: settings?.email.channels.includes("all") ?? true,
            },
            notifications: {
                notifications: settings?.notifications.notifications ?? true,
                channel_tasks: settings?.notifications.channels.includes("all") ?? true,
            },
            personal: {
                job_title: loggedInUser?.jobTitle,
                family_name: loggedInUser?.familyName,
                given_name: loggedInUser?.givenName,
                timezone: settings?.personal.timezone ?? resolvedOptions.timeZone,
                locale: settings?.personal.locale ?? resolvedOptions.locale,
            },
        });
    }, [form, loggedInUser]);

    const onSubmit = (data: z.infer<typeof SettingsSchema>) => {
        const settings = {
            job_title: loggedInUser?.jobTitle,
            given_name: loggedInUser?.givenName,
            family_name: loggedInUser?.familyName,
            settings: loggedInUser?.settings,
        }
        if (!settings.settings) {
            settings.settings = {
                email: {
                    notifications: false,
                    frequency: "instant",
                    channels: [],
                },
                personal: {},
                history: {},
                notifications: {
                    notifications: false,
                    channels: [],
                },
                security: {},
            };
        }

        settings.job_title = data.personal.job_title;
        settings.given_name = data.personal.given_name;
        settings.family_name = data.personal.family_name;
        settings.settings.email.notifications = data.email.notifications;
        settings.settings.email.frequency = data.email.frequency;
        settings.settings.email.channels = data.email.channel_tasks ? ["all"] : [];
        settings.settings.notifications.notifications = data.notifications.notifications;
        settings.settings.notifications.channels = data.notifications.channel_tasks ? ["all"] : [];
        settings.settings.personal.timezone = data.personal.timezone;
        settings.settings.personal.locale = data.personal.locale;

        apiFetch<UserData>('/user/settings', { method: "PUT", headers: { "Content-Type": "application/json" }, body: JSON.stringify(settings) })
            .then((user) => {
                setLoggedInUser(user);
            })
            .catch(async (err) => {
                console.error(await err.text());
                form.reset();
            });
    };

    const org = loggedInUser?.org;
    const orgAdmin = isOrgAdmin(loggedInUser);
    const orgRole = orgRoleString(loggedInUser?.orgRole);
    const width = narrow ? "w-full" : "w-1/2";

    const [cancelText, setCancelText] = useState("");
    useEffect(() => {
        if (orgAdmin) {
            getSubscriptionPlan().then(setSubscriptionPlan);
        }
    }, [loggedInUser]);

    const checkConfirm = () => {
        if (cancelText.toLowerCase() === "cancel") {
            deactivateSubscription().then(() => {
                toast.info("Your subscription has been canceled");
            }).catch(() => {
                toast.error("There was an error canceling your subscription, please contact support");
            });
        } else {
            alert("Your subscription was not canceled. Please type 'cancel' to confirm");
        }
    };

    return <AppPage active="settings">
        <DetailCard
            title="Settings"
            titleIcon={<FaCog className="text-xl mt-1" />}
        >
            <ScrollArea className="p-8 detail-grid__scrolling-area">
                {loggedInUser && <ImageContextualDisplay
                    userId={loggedInUser.id}
                    jobTitle={loggedInUser.jobTitle}
                    maxZoom={3}
                    zoomStep={0.2}
                    rotationStep={10}
                />}
                <div className="extra-padding flex flex-col items-center rounded-lg border p-4">
                    <h2 className="mb-2">Personalization</h2>
                    <h4 className="mb-2">Theme</h4><div />
                    <Button variant="outline" size="icon" onClick={tristateToggle}>
                        {themeIcon()} <span className="sr-only">Toggle theme</span>
                    </Button>
                    <Form {...form}>
                        <form className={width} onSubmit={form.handleSubmit(onSubmit)}>
                            <h4 className="mt-2 mb-2">Email</h4>
                            <FormField
                                control={form.control}
                                name="email.notifications"
                                render={({ field }) => (<FormItem>
                                    <FormLabel>Email Notifications</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormDescription>Set to receive email notifications</FormDescription>
                                </FormItem>)}
                            />
                            <FormField
                                control={form.control}
                                name="email.frequency"
                                render={({ field }) => (<FormItem>
                                    <FormLabel>Notification Frequency</FormLabel>
                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select a frequency" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectItem className="select__hover" value="instant">Instant</SelectItem>
                                                <SelectLabel>Coming soon...</SelectLabel>
                                            </SelectGroup>
                                            {/* <SelectItem className="select__hover" value="halfHour">Bundled every 30 minutes</SelectItem> */}
                                            {/* <SelectItem className="select__hover" value="daily">Bundled daily</SelectItem> */}
                                        </SelectContent>
                                    </Select>
                                    <FormDescription>How often you receive notifications</FormDescription>
                                </FormItem>)}
                            />
                            <FormField
                                control={form.control}
                                name="email.channel_tasks"
                                render={({ field }) => (<FormItem>
                                    <FormLabel>Notification Channel: Tasks</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormDescription>Set to receive email notifications on watched tasks</FormDescription>
                                </FormItem>)}
                            />
                            <h4 className="mt-2 mb-2">App Notifications</h4>
                            <FormField
                                control={form.control}
                                name="notifications.notifications"
                                render={({ field }) => (<FormItem>
                                    <FormLabel>Notifications</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormDescription>Set to receive in-app notifications</FormDescription>
                                </FormItem>)}
                            />
                            <FormField
                                control={form.control}
                                name="notifications.channel_tasks"
                                render={({ field }) => (<FormItem>
                                    <FormLabel>Notification Channel: Tasks</FormLabel>
                                    <FormControl>
                                        <Switch checked={field.value} onCheckedChange={field.onChange} />
                                    </FormControl>
                                    <FormDescription>Set to receive in-app notifications for updated tasks</FormDescription>
                                </FormItem>)}
                            />
                            <h4 className="mt-2 mb-2">Info</h4>
                            <FormField
                                control={form.control}
                                name="personal.job_title"
                                render={({ field }) => (<FormItem>
                                    <FormLabel>Job Title</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormDescription>Your current job title, displayed on your profile</FormDescription>
                                </FormItem>)}
                            />
                            <FormField
                                control={form.control}
                                name="personal.given_name"
                                render={({ field }) => (<FormItem>
                                    <FormLabel>First Name</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormDescription>Your given name</FormDescription>
                                </FormItem>)}
                            />
                            <FormField
                                control={form.control}
                                name="personal.family_name"
                                render={({ field }) => (<FormItem>
                                    <FormLabel>Last Name</FormLabel>
                                    <FormControl>
                                        <Input {...field} />
                                    </FormControl>
                                    <FormDescription>Your family name</FormDescription>
                                </FormItem>)}
                            />
                            <FormField
                                control={form.control}
                                name="personal.timezone"
                                render={({ field }) => (<FormItem>
                                    <FormLabel>Timezone</FormLabel>
                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select Timezone" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            {/* Pacific
                                            <SelectItem className="select__hover" value="Etc/GMT+12">(GMT-12:00) International Date Line West</SelectItem>
                                            <SelectItem className="select__hover" value="Pacific/Midway">(GMT-11:00) Midway Island, Samoa</SelectItem> */}
                                            <SelectGroup>
                                                <SelectLabel>Americas</SelectLabel>
                                                <SelectItem className="select__hover" value="Pacific/Honolulu">(GMT-10:00) Hawaii</SelectItem>
                                                <SelectItem className="select__hover" value="America/Anchorage">(GMT-09:00) Alaska</SelectItem>
                                                <SelectItem className="select__hover" value="America/Los_Angeles">(GMT-08:00) Pacific Time (US & Canada)</SelectItem>
                                                <SelectItem className="select__hover" value="America/Denver">(GMT-07:00) Mountain Time (US & Canada)</SelectItem>
                                                <SelectItem className="select__hover" value="America/Chicago">(GMT-06:00) Central Time (US & Canada)</SelectItem>
                                                <SelectItem className="select__hover" value="America/New_York">(GMT-05:00) Eastern Time (US & Canada)</SelectItem>
                                                <SelectLabel>Other regions coming soon...</SelectLabel>
                                            </SelectGroup>

                                            {/* South America
                                            <SelectItem value="America/Tijuana">(GMT-08:00) Tijuana, Baja California</SelectItem>
                                            <SelectItem value="America/Mexico_City">(GMT-06:00) Guadalajara, Mexico City, Monterrey</SelectItem>
                                            <SelectItem value="America/Caracas">(GMT-04:00) Caracas, La Paz</SelectItem>
                                            <SelectItem value="America/Santiago">(GMT-04:00) Santiago</SelectItem>
                                            <SelectItem value="America/Buenos_Aires">(GMT-03:00) Buenos Aires, Georgetown</SelectItem>
                                            <SelectItem value="Atlantic/South_Georgia">(GMT-02:00) Mid-Atlantic</SelectItem>
                                            <SelectItem value="Atlantic/Cape_Verde">(GMT-01:00) Cape Verde Is.</SelectItem> */}
                                            {/* Europe
                                            <SelectItem value="Europe/London">(GMT+00:00) London, Dublin</SelectItem>
                                            <SelectItem value="Europe/Lisbon">(GMT+00:00) Lisbon</SelectItem>
                                            <SelectItem value="Africa/Casablanca">(GMT+00:00) Casablanca</SelectItem>
                                            <SelectItem value="Europe/Paris">(GMT+01:00) Paris, Berlin</SelectItem>
                                            <SelectItem value="Europe/Brussels">(GMT+01:00) Brussels, Copenhagen</SelectItem>
                                            <SelectItem value="Europe/Warsaw">(GMT+01:00) Warsaw, Zagreb</SelectItem> */}
                                            {/* Africa and Asia
                                            <SelectItem value="Europe/Istanbul">(GMT+02:00) Istanbul</SelectItem>
                                            <SelectItem value="Asia/Jerusalem">(GMT+02:00) Jerusalem</SelectItem>
                                            <SelectItem value="Europe/Kiev">(GMT+02:00) Kiev</SelectItem>
                                            <SelectItem value="Africa/Cairo">(GMT+02:00) Cairo</SelectItem>
                                            <SelectItem value="Europe/Athens">(GMT+02:00) Athens, Bucharest</SelectItem>
                                            <SelectItem value="Asia/Baghdad">(GMT+03:00) Baghdad</SelectItem>
                                            <SelectItem value="Europe/Moscow">(GMT+03:00) Moscow, St. Petersburg</SelectItem>
                                            <SelectItem value="Africa/Nairobi">(GMT+03:00) Nairobi</SelectItem>
                                            <SelectItem value="Asia/Tehran">(GMT+03:30) Tehran</SelectItem>
                                            <SelectItem value="Asia/Dubai">(GMT+04:00) Abu Dhabi, Muscat</SelectItem>
                                            <SelectItem value="Asia/Yerevan">(GMT+04:00) Yerevan</SelectItem>
                                            <SelectItem value="Asia/Kabul">(GMT+04:30) Kabul</SelectItem>
                                            <SelectItem value="Asia/Karachi">(GMT+05:00) Islamabad, Karachi</SelectItem>
                                            <SelectItem value="Asia/Yekaterinburg">(GMT+05:00) Yekaterinburg</SelectItem>
                                            <SelectItem value="Asia/Kolkata">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</SelectItem>
                                            <SelectItem value="Asia/Kathmandu">(GMT+05:45) Kathmandu</SelectItem>
                                            <SelectItem value="Asia/Almaty">(GMT+06:00) Almaty, Novosibirsk</SelectItem>
                                            <SelectItem value="Asia/Dhaka">(GMT+06:00) Dhaka</SelectItem>
                                            <SelectItem value="Asia/Yangon">(GMT+06:30) Yangon (Rangoon)</SelectItem>
                                            <SelectItem value="Asia/Bangkok">(GMT+07:00) Bangkok, Hanoi, Jakarta</SelectItem>
                                            <SelectItem value="Asia/Ho_Chi_Minh">(GMT+07:00) Ho Chi Minh City</SelectItem>
                                            <SelectItem value="Asia/Shanghai">(GMT+08:00) Beijing, Chongqing, Hong Kong</SelectItem>
                                            <SelectItem value="Asia/Singapore">(GMT+08:00) Singapore, Kuala Lumpur</SelectItem>
                                            <SelectItem value="Asia/Tokyo">(GMT+09:00) Tokyo, Osaka</SelectItem>
                                            <SelectItem value="Asia/Seoul">(GMT+09:00) Seoul</SelectItem>
                                            <SelectItem value="Asia/Yakutsk">(GMT+09:00) Yakutsk</SelectItem> */}
                                            {/* Australia and Pacific
                                            <SelectItem value="Australia/Sydney">(GMT+10:00) Sydney</SelectItem>
                                            <SelectItem value="Australia/Brisbane">(GMT+10:00) Brisbane</SelectItem>
                                            <SelectItem value="Pacific/Guam">(GMT+10:00) Guam, Port Moresby</SelectItem>
                                            <SelectItem value="Asia/Vladivostok">(GMT+10:00) Vladivostok</SelectItem>
                                            <SelectItem value="Pacific/Auckland">(GMT+12:00) Auckland, Wellington</SelectItem>
                                            <SelectItem value="Pacific/Fiji">(GMT+12:00) Fiji, Marshall Is.</SelectItem>
                                            <SelectItem value="Pacific/Tongatapu">(GMT+13:00) Nuku'alofa</SelectItem> */}
                                        </SelectContent>
                                    </Select>
                                    <FormDescription>Your current time zone</FormDescription>
                                </FormItem>)}
                            />
                            <FormField
                                control={form.control}
                                name="personal.locale"
                                render={({ field }) => (<FormItem>
                                    <FormLabel>Locale</FormLabel>
                                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                                        <FormControl>
                                            <SelectTrigger>
                                                <SelectValue placeholder="Select Locale" />
                                            </SelectTrigger>
                                        </FormControl>
                                        <SelectContent>
                                            <SelectGroup>
                                                <SelectItem className="select__hover" value="en-US">English (United States)</SelectItem>
                                                <SelectLabel>Localization coming soon...</SelectLabel>
                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <FormDescription>Your current locale</FormDescription>
                                </FormItem>)}
                            />

                            <Button className="mt-4 w-1/2 btn" type="submit"> Save </Button>
                        </form>
                    </Form>
                </div>

                <div className="extra-padding grid grid-cols-2 items-center rounded-lg border p-4 mt-2">
                    <h4 className="mb-2 col-span-2">Organization</h4>

                    <><Label>Name:</Label> <p>{org?.name}</p></>
                    {org?.metadata.description && <><Label>Description:</Label><p>{org.metadata.description}</p></>}
                    <><Label>Role:</Label> <p>{orgRole}</p></>

                    {orgAdmin && <>
                        {org?.metadata.max_capacity && org?.metadata.max_capacity < 1E6 &&
                            <><Label>Max Capacity: </Label><p>{org.metadata.max_capacity}</p></>}
                        {org?.metadata.max_projects && org?.metadata.max_projects < 1E6 &&
                            <><Label>Max Projects: </Label><p>{org.metadata.max_projects}</p></>}
                        {org?.metadata.credits && <><Label>Credits:</Label><p>{org.metadata.credits}</p></>}
                        {org?.metadata.last_payment_date && <><Label>Last Payment Date: </Label><p>{org.metadata.last_payment_date}</p></>}
                        {org?.metadata.overdue_since && <><Label>Overdue Since: </Label><p>{org.metadata.overdue_since}</p></>}
                        {org?.metadata.region && <><Label>Region: </Label><p>{org.metadata.region}</p></>}
                        {org?.metadata.timezone && <><Label>Timezone: </Label><p>{org.metadata.timezone}</p></>}
                        {org?.metadata.contact && <><Label>Contact: </Label><p>{org.metadata.contact}</p></>}
                        {org?.metadata.phone && <><Label>Phone: </Label><p>{org.metadata.phone}</p></>}
                    </>}
                </div>

                {subscriptionPlan && <>
                    <div className="extra-padding grid grid-cols-2 items-center rounded-lg border p-4 mt-2">
                        <h4 className="mb-2 col-span-2">Subscription</h4>
                        <Label>Plan:</Label> <p>{subscriptionPlan.plan}</p>
                        <Label>Status:</Label> <p>{subscriptionPlan.status}</p>
                        <Label>Start Date:</Label> <p>{subscriptionPlan.startDate}</p>
                        <Label>Billing Date:</Label> <p>{subscriptionPlan.billingDate}</p>
                        {subscriptionPlan.trialEnd && <><Label>Trial End:</Label><p>{subscriptionPlan.trialEnd}</p></>}
                        <Dialog>
                            <DialogTrigger>
                                <Button className="mt-4 col-span-2 btn">Cancel Subscription</Button>
                            </DialogTrigger>
                            <DialogContent>
                                <DialogTitle>Are you sure you want to cancel your subscription?</DialogTitle>
                                <DialogDescription>Any usage beyond the basic plan will be disabled and your excess projects and team members will be archived.</DialogDescription>
                                <Input value={cancelText} onChange={(e) => setCancelText(e.target.value)} placeholder="Type 'cancel' to confirm" />
                                <DialogClose className="flex justify-end">
                                    <Button className="btn bg-red-500 dark:bg-hellotrope-400" onClick={checkConfirm}>Confirm</Button>
                                    <Button className="btn">Back</Button>
                                </DialogClose>
                            </DialogContent>
                        </Dialog>
                    </div>
                </>}
            </ScrollArea>
        </DetailCard>
    </AppPage >;
};
export default UserSettings;
