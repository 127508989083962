import { FaXmark, FaRegFloppyDisk, FaRegNewspaper } from "react-icons/fa6";
import { FaTasks, FaEdit } from "react-icons/fa";
import React, { useCallback, useState } from "react";
import { TaskData } from "../../../contexts/Task";
import Personelle, { PersonelleSelector } from "../../Personelle";
import { eventEmitter, useAppContext, useTasksContext, useProjectsContext, apiFetch, useUsersContext } from "../../../contexts";
import DetailGrid, {
    DetailContentArea,
    DetailContent,
    DetailControls,
    DetailSidePanel,
    DetailControl,
} from "../DetailGrid";
import { useNavigate } from "react-router-dom";
import { UserData } from "../../../contexts/User";
import Priority from "./Priority";
import { EditDateTime } from "../EditableDropdown";
import Floater from "../../Floater";

interface TaskCreateData {
    projectId: string;
    description: string;
    assigneeId?: string;
    priority: number;
    dueDate?: string;
}

function TaskCreate() {
    const navigate = useNavigate();
    const { loggedInUser, narrow } = useAppContext();
    const { activeProject } = useProjectsContext();
    const { users } = useUsersContext();
    const { addTask } = useTasksContext();
    const [previewState, setPreviewState] = useState(false);
    const [author] = useState<UserData | null>(loggedInUser || null);

    const [taskData, setTaskData] = useState<TaskCreateData>({
        projectId: activeProject?.id || "",
        description: "",
        priority: 0
    });
    const modalRef = React.createRef<HTMLDivElement>();

    const vanish = () => {
        navigate(-1);
    };

    function handleDescriptionChange(
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) {
        if (event.target !== null) {
            const change = event.target.value;
            setTaskData({
                ...taskData,
                description: change,
            });
        }
    }

    function assignUser(userId: string) {
        setTaskData({
            ...taskData,
            assigneeId: userId,
        });
    }

    const onSetPriority = useCallback((value: number) => {
        setTaskData({
            ...taskData,
            priority: value
        });
    }, [taskData]);

    function togglePreview() {
        setPreviewState(!previewState);
    }

    const onSetDate = useCallback((dateString: string | null) => {
        setTaskData({
            ...taskData,
            dueDate: dateString || undefined
        });
    }, [taskData]);

    async function callSaveApi(
        data: TaskCreateData,
    ): Promise<TaskData> {
        return await apiFetch("/task", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }, "Failed to save task");
    }

    function saveTask() {
        callSaveApi(taskData).then(addTask).catch(() => {
            eventEmitter.emit("ERROR", "Failed to save task");
        });
        vanish();
    }

    const DetailContentMode = !narrow ? (
        <DetailContentArea>
            <DetailContent
                text={taskData.description}
                editMode={!previewState}
                enableAutoFocus={true}
                onChange={handleDescriptionChange}
            />
        </DetailContentArea>
    ) : (
        <DetailContentArea>
            <div className="m-2 items-center rounded-lg border p-4">
                <Floater content="Author of this task">
                    {author && <Personelle userData={author} tag="author" />}
                </Floater>
                <div className="h-2" />
                <Floater content="Assignee for this task">
                    <PersonelleSelector
                        selectedUserData={users.find((user) => user.id == taskData.assigneeId)}
                        tag="assignee"
                        userSelectionShortList={users}
                        handleUserSelect={assignUser}
                    />
                </Floater>
            </div>
            <Priority value={taskData.priority} onSetPriority={onSetPriority} />
            <EditDateTime
                textLabel="Due Date"
                date={taskData.dueDate}
                onCompleted={onSetDate}
            />
            <DetailContent
                text={taskData.description}
                editMode={!previewState}
                enableAutoFocus={true}
                onChange={handleDescriptionChange}
            />
        </DetailContentArea>
    );

    const DetailSidePanelMode = !narrow ? (
        <DetailSidePanel>
            <div className="m-2 items-center rounded-lg border p-4">
                <Floater content="Author of this task">
                    {author && <Personelle userData={author} tag="author" />}
                </Floater>
                <div className="h-2" />
                <Floater content="Assignee for this task">
                    <PersonelleSelector
                        selectedUserData={users.find((user) => user.id == taskData.assigneeId)}
                        tag="assignee"
                        userSelectionShortList={users}
                        handleUserSelect={assignUser}
                    />
                </Floater>
            </div>
            <Priority value={taskData.priority} onSetPriority={onSetPriority} />
            <EditDateTime
                textLabel="Due Date"
                date={taskData.dueDate}
                onCompleted={onSetDate}
            />
        </DetailSidePanel>
    ) : <div />;

    return (
        <div ref={modalRef} className="card-detail" >
            <div className="card-detail__top-bar">
                <div className="card__icon">
                    <FaTasks />
                </div>
                <div className="flex-grow" />
                <div className="card-detail__header-container">
                    <h2 className="card-detail__header">Create a Task</h2>
                </div>
                <div className="flex-grow" />
                <div className="card-detail__close" onClick={vanish}>
                    <FaXmark />
                </div>
            </div>
            <DetailGrid narrow={narrow}>
                <DetailControls>
                    <DetailControl
                        icon={previewState ? <FaEdit /> : <FaRegNewspaper />}
                        text={previewState ? "Edit" : "Preview"}
                        onClick={togglePreview}
                    />
                    <DetailControl
                        icon={<FaRegFloppyDisk />}
                        text="Save"
                        onClick={saveTask}
                    />
                </DetailControls>
                {DetailSidePanelMode}
                {DetailContentMode}
            </DetailGrid>
        </div>
    );
}

export default TaskCreate;
